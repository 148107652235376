import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Vozes(){
    let images = [
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/01_Vozes_em_NY_2022-2.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/02_Vozes_em_NY_1997-3.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/03_Vozes_em_NY_1997-12.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/04_Vozes_em_NY_1997-7.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/05_Vozes_em_NY_1997-10.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/06_Vozes_em_NY_1997_12.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/07_Vozes_em_NY_1997-8.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/08_Vozes_em_NY_1997-9.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/09_Vozes_em_NY_1997_14.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/10_Vozes_em_NY_1997-13 copy.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/11_Vozes_em_NY_1997-5.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/12_Vozes_em_NY_1997-15.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        nas bocas
                    </div>
                    <div className="namidia-text-subtitle">
                    Vozes em NY
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Desde pelo menos a década de 30 a rua foi sempre um tema presente na história da fotografia, por meio de inúmeros aspectos retratados: transeuntes, guetos, ruas, becos, bares, vitrines, parques, arranha-céus, multidões anônimas etc. Esse ensaio procura dialogar com essa tradição, mas em vez de revelar as coisas em si, busca uma apreensão da paisagem urbana refletida nas vozes alheias ouvidas aqui e acolá e devolvidas às ruas.  Juntos os fragmentos recompõem o tumulto humano novaiorquino. As fotografias foram realizadas no final dos anos 1990.  
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            Série apresentada com o título "Rastros", dentro do Projeto Prima Obra da FUNARTE – Brasília, 1998.
                        </div>
                        <div>
                            Instalação com fios e fotografia em sais de prata. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}