import { useEffect } from "react";
import Header from "../../components/Header";
import TopBarImage from "../../components/TopBarImage";
import "../../css/screens/fotografia/NaMidia.css"
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NaMidia(){
    const navigate = useNavigate();
    
    function addImageAnimation(nometexto:string,  nomeimage:string){
        let image:any = document.querySelector(nomeimage);
        let text = document.querySelector(nometexto);
        
        text?.addEventListener('mouseover', ()=>{
            image.style.opacity = 1;
        })

        text?.addEventListener('mouseout', ()=>{
            image.style.opacity = 0;
        })
    }

    function addRedirect(element:string, src:string){
        let dv:any = document.querySelector(element);

        dv?.addEventListener('click', ()=>{
            navigate(src);
        })
    }

    useEffect(()=>{
        addImageAnimation('.namidia-text-mundo', '.image-mundo')
        addImageAnimation('.namidia-text-verao', '.image-verao')
        addImageAnimation('.namidia-text-vento', '.image-vento')
        addImageAnimation('.namidia-text-glossario', '.image-glossario')
        addImageAnimation('.namidia-text-sala', '.image-sala')

        addRedirect('.namidia-text-mundo', '/site/fotografia/namidia/mundo')
        addRedirect('.namidia-text-verao', '/site/fotografia/namidia/verao')
        addRedirect('.namidia-text-vento', '/site/fotografia/namidia/vento')
        addRedirect('.namidia-text-glossario', '/site/fotografia/namidia/glossario')
        addRedirect('.namidia-text-sala', '/site/fotografia/namidia/sala')
    }, [])

    return <>
        <Header />
        <TopBarImage />
        <div className="namidia-mainContainer">
            <Container>
                <Row className="namidia-textoContainer">
                    <Col>
                        <Row className="namidia-text-namidia">
                            na mídia
                        </Row>
                        <Row className="namidia-text-mundo">
                            Mundo
                        </Row>
                        <Row className="namidia-text-verao">
                            Notícias de Verão
                        </Row>
                        <Row className="namidia-text-vento">
                            O que o vento ainda não levou
                        </Row>
                        <Row className="namidia-text-glossario">
                            Pequeno glossário para explicar um estranho fenômeno
                        </Row>
                        <Row className="namidia-text-sala">
                            A Sala
                        </Row>
                    </Col>
                    <Col>
                    <Row className="namidia-imageContainer">
                        <div className="image-mundo">
                            <img src="/assets/fotografia/mundo/01.jpg" />
                        </div>
                        <div className="image-verao">
                            <img src="/assets/fotografia/verao/01_Dobal_palmeiras_mn.jpg" />
                        </div>
                        <div className="image-vento">
                            <img src="/assets/fotografia/vento/01_Dobal_Figos_ECD12D7E-D125_1000px_mn.jpg" />
                        </div>
                        <div className="image-glossario">
                            <img src="/assets/fotografia/glossario/01_novo_1000px_mn.jpg" />
                        </div>
                        <div className="image-sala">
                            <img src="/assets/fotografia/sala/01_A_Sala_1000px.jpg" />
                        </div>
                    </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    </>
}