import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import "../css/components/Header.css"
import {useNavigate} from "react-router-dom";

export default function Header(){
    const navigate = useNavigate();

    return <>
        <Navbar className="mainHeader" expand={"xl"} >
            <Container style={{backgroundColor: "#707B39"}}>
                <Navbar.Brand href="/#/" className="logoHeader mx-auto w-50">susana dobal</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav >
                        <NavDropdown title="fotografia" className="color-bege">
                            <NavDropdown.Item href="/#/site/fotografia/namidia">
                                na mídia
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/fotografia/nomundo">
                                no mundo
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/fotografia/nasbocas">
                                nas bocas
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="texto" className="color-bege">
                            <NavDropdown.Item href="/#/site/texto/artigos">artigos</NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/texto/livros">livros</NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/texto/entrevistas">entrevistas</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="bio"  className="color-bege">
                            <NavDropdown.Item href="/#/site/bio/minibio">mini bio</NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/bio/cv">cv</NavDropdown.Item>
                            <NavDropdown.Item href="/#/site/bio/contato">contato</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}