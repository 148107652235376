import React, { useState } from 'react';
import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/texto/Entrevistas.css";
import CarouselDefault from "../../../components/CarouselDefault";
import EntrevistaImageModal from "../../../components/EntrevistaImageModal";



export default function KarinaDias(){
    const array15 = Array(12).fill(false);

    /*** Modal Control  */
    const [modalControl, setModalControl] = useState(Array(12).fill(false));

    const handleClose = (n:number) => () => {let m = modalControl.slice(); m[n]=false; setModalControl(m);}
    const handleOpen = (n:number) => () => {let m = modalControl.slice(); m[n]=true; setModalControl(m);}
    const ModalLink = (props:any) => (
        <a onClick={handleOpen(props.n)} className='modal-link'>
            <b>
                &nbsp;[fig. {props.n+1}]
            </b>
        </a>
    );

    /*** Image control */
    function getImagePath(n:number){
        return  "assets/texto/entrevistas/01_Karina Dias/Imagens_Entrevista_Karina_Dias/" + 
        n.toString().padStart(2, '0') + 
        "_Karina Dias.jpg"
    }

    function generateSrc(index:any){
        return {
            src: getImagePath(index),
            legenda: (<>
                <b>Figura {index}</b>
            </>)
        }
    }

    let images = () => new Array(12).fill(0).map((e, idc)=>{
        return generateSrc(idc + 1);
    })

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="entrevista-mainBody">
                <Row className="entrevista-titulo">
                    <b>
                        Paisagens invistas - entrevista com Karina Dias
                    </b> 
                </Row>
                <Row>
                    <div className="entrevista-sub-text">
                        Entrevista originalmente  publicada no livro  A paisagem como narrativa. DOBAL, Susana e CASTANHEIRA, Rafael (eds.) . Brasília: FAC Livros, 2022.
                    </div>
                    <div className="entrevista-sub-text">
                        <a>
                        https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/  
                        </a>
                    </div>
                    <div className="entrevista-sub-text">
                    Entrevista e fotos Susana Dobal 
                    </div>
                </Row>
                <Row className="entrevista-texto">
                    <p>
                        Karina Dias faz uma reflexão sobre a paisagem traduzida em obras que utilizam a fotografia, o vídeo e instalações em percurso de diversas exposições no Brasil e na França, e mais de vinte anos de dedicação a esse tema. Ela também escreveu o livro Entre Visão e Invisão: Paisagem onde expõe conceitos importantes para a obra que realiza e situa as questões que investiga no percurso da noção de paisagem na história da arte. O conceito de invisão é fundamental para ela e se baseia na ideia (in)vu, em que o prefixo in-  carrega ao mesmo tempo uma negação e uma inclusão. Karina Dias traduziu o termo em francês como n[ã]o visto, algo que existe no visível mas que pode passar despercebido e precisa ser (re)descoberto em um tarefa de criar pertencimento ao espaço em volta. Duas vertentes principais informam a produção da artista e sua pesquisa sobre a paisagem: o que ela chama de situ-visão, trabalhos realizados principalmente em Paris e que exploram diferentes formas de captação e projeção de vídeo, e situ-ação, intervenções na paisagem, e uso de dispositivos como cubos espelhados, um cata-vento ou placas móveis que refletem e reconstituem a paisagem em uma busca por aumentar a percepção do espectador sobre o seu espaço cotidiano. As duas vertentes não se excluem, pois projetar um vídeo pode ser também uma maneira de intervir na galeria já que vê-lo em uma tela pequena ou estar envolto por imagens em movimento nas paredes produzem percepções diferentes e, por outro lado, intervir na paisagem é também uma forma de criar maneiras de ver o espaço ao redor.  
                    </p>
                    <p>
                        Essa entrevista foi realizada na casa de Karina Dias, em 2019, antes da pandemia, quando tínhamos a perspectiva de realizar o colóquio no começo de 2020, e algumas das fotos foram feitas na exposição dela na Galeria da Caixa Econômica, em Brasília, em 2017. A entrevista faz parte de uma série de outras realizadas com artistas, fotógrafos e teóricos que tratam da fotografia, com o intuito de refletir sobre a produção de imagens a partir da fala do entrevistado e sua obra. A entrevista é sempre acompanhada por um ensaio fotográfico paralelo que explora as ideias e a obra da pessoa entrevistada. Para esse ensaio, foram utilizados trechos de textos de Karina Dias - além do livro citado acima, também o artigo Notas sobre a montanha  - que elucidam aspectos relacionados à percepção dela do conceito de paisagem.  O ponto em comum nessa série de entrevistas é o fato de explorar as ideias comentadas por meio de imagens e, com os olhos abertos, assimilar o pensamento do entrevistado, aqui aplicado à paisagem vista do carro pelas vias brasilienses. Essa narrativa paralela pode ser acessada por meio de links para as figuras que levam às verbo-imagens. Nelas, além do texto da artista, a noção de paisagem foi explorada em assombros fugazes, no trânsito, como se ao deslizar nas pistas cotidianas fosse possível descortinar o que Karina Dias chama de “lampejos de visão” - eles remetem a um além, incrustados em um aquém.
                    </p>
                    <div className="green-box">
                        Apesar de você usar muito o vídeo, o seu olhar é bastante fotográfico pois se atém a planos longos e estáticos em que pouca coisa acontece.  Por que a preferência pelo vídeo?
                    </div>
                    <p>
                        O vídeo apareceu desde cedo na minha produção que começa com instalações urbanas e videoinstalações. O vídeo apareceu de forma mais intensa nas videoprojeções, sem as estruturas mais arquitetônicas que acolhiam os vídeos, televisões e tal, quando fui fazer o doutorado na França em 2003 e por não ter ainda organizado meu ateliê na França eu comecei a sair só com a câmera mini DV. A minha relação com o vídeo é quase uma extensão do olho, um prolongamento da visão. As minhas referências são muito mais pictóricas e o vídeo é uma maneira de prolongar esse efeito na paisagem. 
                        <ModalLink n={0}/>.
                    </p>
                    <div className="green-box">
                        Então os planos parados e bem abertos seriam mais uma influência da pintura do que da fotografia?
                    </div>
                    <p>
                        Mais uma influência da pintura de vários períodos da História da Arte, desde a Idade Média até o contemporâneo. O Plano parado é uma constante no meu trabalho porque o vídeo para mim é o meu olho continuando, é uma forma de estender ao máximo aquele momento em que eu parei e olhei. Eu não me interesso muito pelos efeitos da linguagem do vídeo, eu me interesso pelo momento em que algum espaço me convocou a parar e olhar, e quanto mais a gente olha o espaço, mais ele vai aparecendo. O plano parado é uma maneira de dar conta dos movimentos mínimos que compõem os espaços. Eu tenho muitas videoprojeções, a imagem projetada. Mas isso é paralelo, eu me interesso muito em criar estruturas de visão, quase que dispositivos óticos. Minhas videoinstalações são dispositivos para olhar. 
                    </p>
                    <div className="green-box">
                        Mas você também usa a fotografia. De uma maneira geral quando você sente a necessidade de usá-la?
                    </div>
                    <p>
                        Eu tenho desenvolvido trabalhos especificamente em fotografia também, já foram apresentados em exposições junto com os vídeos. Quando estou filmando, eu fico fotografando também, isso fica como registro do próprio vídeo e muitas vezes isso se torna depois o trabalho. Por exemplo, eu tenho uma série, Diário de Bordo, em que fotografei de 2012 a 2016 só cumes de montanhas. Eu fotografava e filmava e as fotos depois se transformaram em um trabalho. Fotografei mais de 700 cumes e escolhi dezoito pra mostrar. E assim também em outros casos. Tem esse outro trabalho que só fiz em fotografia [ela aponta para a parede], eu chamo de Distâncias, ou Como percorrer uma planície, eu fui fotografando a minha filha percorrendo a planície, ela não sabia.
                    </p>
                    <div className="green-box">
                        No caso dessa obra Distâncias, o trabalho fotogreafico é meio cinematográfico assim como o vídeo que você faz é muitas vezes fotográfico. As imagens estão paradas, mas tem um movimento pela sequência. Por que nesse caso você achou melhor usar fotografia e não vídeo se há movimento? 
                    </div>
                    <p>
                    Os meus trabalhos surgem nos lugares. Toda vez que começo a planejar demais, não dá certo porque parte desse encontro com o lugar. Ali a gente estava em um grande plateau e eu comecei a observar o deslocamento dela no espaço e a fotografar. Eu me interessei por uma métrica do espaço medida pelo deslocamento dela.
                    </p>
                    <div className="green-box">
                    Tem mesmo uma obra sua que é justamente Como medir um exíguo espaço de terra em que você aparece caminhando em frente a uma montanha...
                    </div>
                    <p>
                    Aquele trabalho, por exemplo, era um estudo em que eu fui testando o espaço. Eu tenho vários estudos assim, que eu faço nos lugares e ao olhar pra eles de novo eles viram trabalho, vão pra galeria e tal. Eu vi que a partir desse e outros que fui realizando em que apareço medindo o espaço, a fotografia entrou também como um frame após frame, um quadro a quadro que vai revelando tanto o deslocamento da pessoa como próprio espaço.
                    </p>
                    <div className="green-box">
                    Em diversas das suas intervenções urbanas você utiliza espelhos ou reflexos. Eles seriam uma forma de levar o espectador a re-ver o espaço ao redor?
                    </div>
                    <p>
                    Sim, as intervenções urbanas e os vídeos, muitos deles em que trabalho a cidade onde moro e vivi intensamente, o desejo era esse mesmo, como apresentar o espaço, como amplificar com superfícies refletoras coisas que não são mais vistas no cotidiano, porque já foram vistas demais e por isso ninguém mais vê. 
                    <ModalLink n={1}/>.
                    </p>
                    <div className="green-box">
                    Tem uma outra obra [Détour/Desvio (2004)] em que você fotografou ou filmou o reflexo em uma poça d'água, que refletia a cidade, refletia Paris.
                    </div>
                    <p>
                    Ali de novo era um caminho que eu pegava cotidianamente e um belo dia o chão virou espelho. Ali também sou eu andando percorrendo esse campo espelhado.
                    </p>
                    <div className="green-box">
                    Esses espelhos seriam uma porta para o (in)vu/n[ã]o visto?
                    </div>
                    <p>
                    Eu acho que uma das portas (risos), esse in(vu) que está fora e dentro do quadro. Essa noção pra mim é muito importante porque é a partir dela que as coisas estão aparecendo, aquilo que está em volta e a gente não vê. 
                    <ModalLink n={2}/>.
                    </p>
                    <div className="green-box">
                    Aquilo que é visível, e ao mesmo tempo o seu dispositivo obriga a olhar o mesmo de outra maneira.
                    </div>
                    <p>
                    Eu tenho uns vinte vídeos editados em Paris, que só tem lugares banais. Muitas vezes as pessoas viam e não reconheciam a própria cidade, são detalhes que eram quase nada e quando amplificados aparecem.
                    </p>
                    <div className="green-box">
                    Ao contrário dos planos gerais das paisagens, os espelhos quebram o espaço em pequenas partes, criando uma mistura de fragmentos. Pode-se dizer que você propõe ao espectador uma remontagem do espaço e não apenas uma contemplação passiva? 
                    </div>
                    <p>
                    Eu acho que tem essa relação de sempre um ponto de vista que são no mínimo dois nos meus trabalhos: ou dia e noite, ou os espelhos que têm várias faces, sempre um que se desdobra e talvez utopicamente tenha esse desejo de que cada uma vá compondo a sua paisagem com aquele detalhe que lhe convier. 
                    <ModalLink n={3}/>.
                    </p>
                    <div className="green-box">
                    Também a ideia de um que são dois é o contrário da perspectiva renascentista que faz com que no quadro tudo participe de uma convergência para um ponto ideal, seja um ponto de fuga da perspectiva linear, seja da posição do espectador. Na maioria das suas obras não tem um lugar único para o espectador, ele pode estar ao mesmo tempo no dia e na noite, na ida e na volta.
                    </div>
                    <p>
                    A relação que eu estabeleço com a paisagem vem desse olhar deslocado, não é uma contemplação passiva, como você disse, mas não é uma contemplação désposta que parte de um ponto de vista privilegiado, “daqui eu domino tudo”, não é essa paisagem que me interessa. Pra mim paisagem não é panorama.
                    </p>
                    <div className="green-box">
                    Apesar de que você também usa alguém contemplado a paisagem, ou você mesma.
                    </div>
                    <p>
                    O lugar do observador é um convite para que o espectador venha olhar comigo. Eu me incluir nas cenas começou lentamente, no final de 2006, e de lá pra cá eu tenho vontade de aparecer olhando.
                    </p>
                    <div className="green-box">
                    Seria mais uma maneira de representar a própria percepção?
                    </div>
                    <p>
                    Não sei se seria um representar, mas um estar junto. 
                    <ModalLink n={4}/>.
                    </p>
                    <div className="green-box">
                    Com o espectador ou com a paisagem?
                    </div>
                    <p>
                    Egoisticamente sou eu estando junto com a paisagem. Os trabalhos surgem de uma experiência intensa com os lugares, não é um soslaio. Quando filmei a montanha foram trinta dias de imersão, para, olha, filma, chove, olha, neva, até que a paisagem vai entrando. Cada vez mais essa contemplação extrema... não gosto da palavra meditativa, mas é de uma atenção extrema. Ao mesmo tempo que você assume um lugar para estar, vou ficando tão atenta à paisagem que é quase uma presença extrema.
                    </p>
                    <div className="green-box">
                    É novamente uma experiência do invu - n[ã]o visto. Você não quer a paisagem por si mesma, você quer a ressonância da paisagem. Então tem a ver você estar presente, porque a procura é pelo que te toca na paisagem, conforme você menciona no livro em relação ao punctum.
                    </div>
                    <p>
                    Pois é, você tem que ter um tempo. Pra mim, o vídeo, como ele afasta um pouco o efeito visual da linguagem, que o meio e a técnica podem dar... eu preciso do tempo. A câmera parada, que é uma coisa fotográfica, as coisas acontecem ali em frente à uma câmera que não se mexe e para que isso seja percebido eu preciso de um tempo de observação, um tempo de espera, um tempo de observação do espectador.
                    </p>
                    <div className="green-box">
                    Além das diferentes estratégias para forçar uma percepção do espaço, você também explora, a propósito, uma temporalidade bem particular. Pode-se dizer que a opção pela lentidão, pela longa duração ou pela simultaneidade de tempos que seriam em princípio lineares (maré alta e maré baixa mostradas simultaneamente, por exemplo, em Seuil/Limite; noite e dia vistos também simultaneamente pela janela do trem em Passager 1/Passageiro 1) aproximam o seu olhar mais da fotografia do que do vídeo? Aproximam de um tempo não linear, no sentido de que a fotografia recorta e traz para o presente sem continuidade.
                    </div>
                    <p>
                    A gente pode pensar em termos fotográficos seja pelo enquadramento, seja pela posição que permanece ali lançando a câmera e decidindo onde parar. Como você resolve onde parar? A maneira como eu trabalho é diferente do cinema, que sabe melhor quando parar. A maneira como trabalho está mais próxima do instante decisivo, muitas vezes na hora que eu desligo a câmera é quando finalmente alguma coisa acontece. Se estou na montanha, eu desligo e a nuvem passa, eu perdi.
                    </p>
                    <div className="green-box">
                    Em geral, parece mais uma crítica do instante decisivo, porque nos seus vídeos, eles não culminam, não chegam a um ponto final e ideal.
                    </div>
                    <p>
                    Tem trabalhos que eu filmei um ano inteiro, o mesmo ponto de vista, o mesmo lugar, fui filmando e depois editei isso. O critério de edição varia. No vídeo em que estou caminhando o critério é andar de um lado para outro da rua, por exemplo. Tem outros que vão até onde entendo que a imagem foi, mas essa é uma decisão quase que orgânica, não é racional.
                    </p>
                    <div className="green-box">
                    Sua obra é toda baseada na observação do espaço e a natureza está bastante presente. Como Brasília, que é uma cidade planejada, poderia de alguma forma ter contribuído pra formar o seu olhar para a paisagem? Ou esse olhar vem de outras fontes?
                    </div>
                    <p>
                    Quando a gente está fora é que a gente percebe como os lugares nos formam. Pra mim, é muito forte a relação com o horizonte e isso não seria tanto se eu não tivesse vivido aqui. A estrutura de montagem de muitos vídeos, a ideia das frestas, vem de Brasília... 
                    <ModalLink n={5}/>.
                    </p>
                    <div className="green-box">
                    Como as noções de detalhe e fragmento atuam na sua obra? 
                    </div>
                    <p>
                    Por muito tempo eu pensei muito nisso, o detalhe como o que pode ser o todo, o detalhe que vira a paisagem que pode ser vista. Hoje com um distanciamento maior, entendo o fragmento como o momento em que desloco do todo, uma relação de deslocamento do todo, voltando para a fotografia, o detalhe transmuta. Um detalhe bem corriqueiro, quando projetado, ele vira o mundo, uma folhinha balançando vira o mundo. Um trabalho que a gente fez filmando os campos floridos na Patagônia, o que me chamou a atenção ali foi a cor amarela e um vento forte. Ali virando a câmera pra baixo, isso transformado em uma instalação vira um mundo inteiro.
                    </p>
                    <div className="green-box">
                    E o desfoque nessa obra, foi na edição posterior ou você trabalhou na hora?
                    </div>
                    <p>
                    Foi na hora. Em muitos trabalhos uso o desfoque. Ele também tem uma relação com o invu, com a falta de nitidez da visão. E tem um acidente da câmera. A minha relação com a câmera, não é em condições ideais. Muitas vezes filmando à noite, a câmera desfocava. E eu comecei a achar mais interessante o desfocado do que o focado. Ali aconteceu a mesma coisa. Teve um problema de foco na hora de regular, o vento era muito intenso, a câmera estava completamente instável e aí deixei e na edição fomos desfocando e editando a imagem. 
                    </p>
                    <div className="green-box">
                    Considerando que as paisagens costumam aparecer em planos gerais e que você explora a paisagem na sua obra, como conciliar esse interesse pelo detalhe?
                    </div>
                    <p>
                    Tem maneiras de tornar isso mais evidente. Esse trabalho que filmei durante um ano o mesmo ponto de vista, Janela 1, a câmera não mexeu, a gente só ligava e desligava, um grande panorama, foi o primeiro trabalho que fiz quando a gente voltou pra cá. Quando voltei [de Paris] não estava conseguindo olhar a cidade [Brasília], pensei: será que a cidade se esgotou? Durante um ano filmamos esse plano, as estações. Filmamos em frestas em que cada uma tem um detalhe do todo - um entardecer, uma bruma, a paisagem nunca está inteira, está sempre em fragmentos. 
                    <ModalLink n={6}/>.
                    </p>
                    <div className="green-box">
                    Além de representar o olhar, a cegueira também é um tema presente em diversas obras em que o espectador é privado de ver uma parte da cena por uma barra escura que empata a visão completa das coisas, como nessas frestas que você menciona. Em uma obra tão preocupada em re-ver, por que enfatizar a cegueira?
                    </div>
                    <p>
                    Eu fui chegando nesse lugar da cegueira por um desejo de compreender como eu olhava, uma experiência fenomenológica do meu olhar. Eu chegava em casa e nem sei o que vi, nesse estado de cegueira eu fui tentando elaborar essas três instâncias que entendo como o movimento do olhar: o ver, o olhar e o ter visto. A gente só vê porque muita coisa ficou pra trás, se elejo uma coisa pra olhar é porque uma série de outras ficou na noite, a gente só vê porque não vê, então somos cegos também. 
                    <ModalLink n={7}/>.
                    A experiência mais poderosa dessa cegueira enquanto vidente - não faço uma apologia da cegueira - foi a experiência da noite. Desde os meus primeiros trabalhos a noite está presente. A noite não urbana, essa é cinzenta, mas o que chamo de noite noturna na natureza, quando o mundo desaparece. 
                    </p>
                    <div className="green-box">
                    A sua presença olhando a paisagem, assim como as barras que tapam a visão, são uma forma de levar o espectador a ter uma consciência da visão - o que estou vendo, o que estou deixando de ver?
                    </div>
                    <p>
                    Essas tarjas pretas além de colocarem em relação duas coisas, um aqui e um ali, talvez sejam como você falou, convocar o espectador a ver a própria percepção.
                    </p>
                    <div className="green-box">
                    A paisagem que você defende como sendo uma visão interna, como conciliar essa paisagem com a fotografia e o vídeo que dependem tanto da presença de algo diante da câmera? 
                    </div>
                    <p>
                    Cada vez que mais vou olhando para a paisagem, maior fica a minha fidelidade à terra, como diz Heidegger. Não é em detrimento do aspecto terrestre, aquilo que estou vendo ali, uma parcela da terra. Mas saber que aquilo ali convoca também quem está olhando uma terra que é sua. Nesse sentido, eu posso estar diante da montanha, mas a montanha do espectador não é a minha montanha. O espectador diante do céu, está diante do céu que ele já viu também.
                    </p>
                    <div className="green-box">
                    E que não tem nada a ver com dados geográficos e precisão. Talvez por isso em uma das suas obras vemos uma fotografia de uma montanha e uma pergunta: como delimitar a fronteira?  
                    </div>
                    <p>
                    Para mim esses são os trabalhos mais fotográficos. Eu chamo de Cartografias, que partem de fotos que fiz e fui traçando cartografias. Na série Fronteiras, na montanha, como saber qual o momento que atravessamos uma fronteira se não tiver uma placa ou for uma zona de guerra, ou qualquer sinal mais evidente?  Eu tenho cada vez mais desejo de estar junto com a paisagem, como Thoreau, estabelecendo uma amizade com a paisagem. Aqui eu tive um pensamento fotográfico de composição com pequenos registros, sempre em pequenos formatos. São questões que aparecem às vezes no caminho. São sempre registros fotográficos em que vou inventando maneiras de traçar o espaço com uma cartografia outra. 
                    <ModalLink n={8}/>.
                    </p>
                    <div className="green-box">
                    No livro, você menciona que Paris foi um espaço gradualmente habitado e “praticado cotidianamente”. Como um espaço pode ser praticado e de que maneira isso se reflete na sua obra? 
                    </div>
                    <p>
                    Em Paris e quando a gente volta de cidades que têm esse fluxo temporal, histórico e de vida urbana, são cidades que acolhem o corpo, diferente de Brasília; no caso de Paris, eu só andava a pé. Mais eu ficava lá, mais eu entendia aquele espaço de todo dia como uma repetição, como cada esquina, cada evento, com um detalhe qualquer, é como se aquilo se reapresentasse. No espaço de rotina, não destinado à contemplação, como a partir da atenção a esse lugar, de repente tem um detalhe que é um punctum que faz com que uma rachadura ocorra e o espaço apareça. Praticar cotidianamente uma cidade é esse amor pelo deslocamento.
                    </p>
                    <div className="green-box">
                    Foi o que te levou depois a fazer  Como medir uma paisagem...
                    </div>
                    <p>
                    Pois é, anos depois, e levo isso para a natureza e não para a cidade.
                    </p>
                    <div className="green-box">
                    Haveria uma coincidência entre esse observador da paisagem e o observador da galeria, pelo menos no sentido de uma repetição de um tempo de contemplação? Quando você vai montar, essa é uma preocupação de repetir o tempo diante da paisagem no tempo de espectador?
                    </div>
                    <p>
                    A montagem em vídeo, embora pareça só um colocar um projetor, esse colocar é uma vida.
                    </p>
                    <div className="green-box">
                    Quanto mais que você trabalha com várias maneiras de projetar...
                    </div>
                    <p>
                    Esse ajuste para que a imagem apareça, toda vez é um espanto. A minha exposição só se torna visível quando você liga. Tem um trabalho enorme de ajuste em cada imagem dessa. O que eu vi lá? -  é isso que preciso conferir. Tem imagens que são mais caprichosas, preciso cuidar delas para que elas não se percam. Quando vou para a galeria não tenho uma preocupação com o espectador, a minha preocupação é chegar na imagem que mantenha o que foi visto por mim. À medida que os trabalhos são apresentados, entro na galeria e vejo quem está na observação, os vídeos são longos, ou curtos mas estão em looping, eles não têm início meio e fim. O espectador chega, muitos entram e saem, muitos também ficam. O espectador tem que ir conquistando o espaço dele dentro dessas imagens em que aparentemente não acontece nada. Pensando aqui, agora, talvez o espectador tenha que ir achando o lugar dele assim como todos nós temos que achar um ponto de vista. 
                    </p>
                    <div className="green-box">
                    Você leva o espectador a uma contemplação diante de longos planos projetados em telas grandes, mas você também inventa dispositivos menores como uma caixa espelhada, ou externos, como um cata-vento espelhado ou um tubo com orifícios para se ver uma cena lá fora. Como esses outros dispositivos contribuem para a percepção que você procura instigar da paisagem? Seriam também formas de encenar o próprio processo da percepção?
                    </div>
                    <p>
                    Esses dispositivos começam sempre com pequenas janelas e exigem uma aproximação; eu sempre fui fascinada por instrumento óticos, binóculos, lunetas, instrumentos de navegação, os que auxiliam o olho. O espectador tem que aproximar e se inclinar. Talvez com um desejo de instigar o espectador... 
                    <ModalLink n={9}/>.
                    Eu não acho que a contemplação seja passiva, não acho que o olhar seja dócil. A contemplação é uma atenção extrema. Mesmo as grandes projeções que são dadas logo como quadro a ser olhado, a partir do momento em que quase nada acontece, ali exige uma persistência do espectador que é incômoda. A gente está acostumado a sempre ver grandes explosões que solicitam uma atenção imediata e depois tudo escapa. A câmera filmando, sem nem deslocamento de plano, as coisas desfilam ali, é difícil parar para olhar.
                    </p>
                    <div className="green-box">
                    Por que trabalhar então com o incômodo?
                    </div>
                    <p>
                    A gente - penso nos artistas em geral - não sei se alguém pensa no espectador. Na exposição, essa preocupação existe, já saímos de onde foi vivido, do atelier onde há a construção do trabalho e aí vamos para um espaço de compartilhamento. Já me perguntaram várias vezes: Karina, você quer pôr banco? As pessoas precisam se sentar, é um tempo que precisa. Na exposição da Caixa (2017), eu não quis nem saber, eram tantas imagens, egoisticamente eu pensei, pode sentar no chão, encostar na parede, ficar em pé, são outras formas de achar uma posição do corpo pra poder olhar. Mas futuramente posso pensar nisso. Nesses trabalhos dos dispositivos, eu criei todo um mobiliário, o praticável, eu elaborei e vendi a obra para colecionadores que compram tudo, a estrutura e o trabalho. 
                    </p>
                    <div className="green-box">
                    Até para o espectador poder se dar a uma contemplação, o corpo tem que estar confortável porque senão nada acontece e ...
                    </div>
                    <p>
                    A perna dói, o pé dói (risos). Quando estou filmando a montanha, mas em Paris também, era uma mini DV, todo turista tem, o corpo vai se acomodando, mas filma colado com a camerazinha na janela, você vê que a câmera não treme, ou em cima da pedra, o corpo não treme, você vai se entregando e se integrando com a pedra.
                    </p>
                    <div className="green-box">
                    A paisagem que você destaca na sua obra precisa da tecnologia do vídeo e da fotografia para acontecer? Ela poderia também ser revelada pelo desenho ou pela pintura?
                    </div>
                    <p>
                    No meu caso acho que não, a minha escolha é pelo vídeo e pela fotografia. Os meus trabalhos são pictóricos mas eles, pelo movimento, dão conta daquilo que eu vi, mesmo que sejam um recorte, um fragmento, um detalhe. A câmera é uma extensão do meu olho, assim que eu entendo e acho que a pintura não daria conta disso.
                    </p>
                    <div className="green-box">
                    A obra das flores amarelas, A vista ( 2017), é bem pictórica mas ela precisa de uma duração para ocorrer o aparecimento e o desaparecimento.
                    </div>
                    <p>
                    Para mim é importante dar tempo para o espaço. A lentidão tem a ver com a forma como eu olho. É muito ligado pra mim a forma como eu olho e como eu filmo. Demorei a entender que meu olho é lento mas não no sentido de estar pra trás. Eu estudo muito a lentidão, encontrar o próprio tempo, não se deixar ser precipitada por um tempo que não te pertence. 
                    <ModalLink n={10}/>.
                    A fotografia dá conta pra mim parcialmente dessa maneira de ver. Até onde eu posso chegar mais perto de um cume? Pra mim isso é maravilhoso, como chegar junto com a câmera. Teve um trabalho que passamos um mês filmando, e chegou uma hora que o zoom não era suficiente, ela tremia, desfocava. E depois assimilamos isso no trabalho, o limite da câmera. Não sou fascinada pela tecnologia, mas entendo a câmera como uma extensão do olho, é um olho que pensa. 
                    </p>
                    <div className="green-box">
                    É um olho que pensa e que você faz pensar de acordo com as suas preocupações porque, por exemplo, as oscilações entre o foco e o desfoque naquela obra das flores amarelas (A vista, de 2017), parece aquela ideia do ver e do não ver.
                    </div>
                    <p>
                    Ele está dentro de um grupo de trabalhos que tem a bruma, a névoa, a noite, mas ali vou até a imagem aparecer. Mas quando a imagem aparece, o vento era muito intenso, a câmera dá uma tremida. Aquilo era um erro da filmagem. Quando a gente montou -  trabalho com o Albert [Ambelakiotis], meu marido - pra mim ficou evidente o lugar dessa tremedeira que tira daquele panoraaaaaama lindo, e dá um abalo, um ruído.
                    </p>
                    <div className="green-box">
                    Para terminar, em um mundo em que vivemos cada vez mais saturados de imagens, e em ritmo frenético, haveria ainda espaço para a paisagem ou essa seria uma noção nostálgica? 
                    </div>
                    <p>
                    A paisagem vem como talvez a possibilidade humana de estar aqui, nesse lugar. Ela é morada também, a possibilidade humana de medida, ela é a medida de um olhar, tem a escala das nossas percepções. A experiência do homem no mundo, a escala é a paisagem. Pensando nas cidades brasileiras, a gente vê claramente a ausência da noção de paisagem. A paisagem é parte, mas é composição também, uma coisa ao lado da outra, com distância, de forma que o espectador faz o elo entre as coisas. Pra mim, utopicamente, a paisagem é extremamente necessária e urgentemente imprescindível nos dias de hoje porque é a possibilidade de compor com os elementos que estão aí. Não temos mais um ponto de vista privilegiado, o espaço urbano é da adversidade, não temos distância, não temos tempo para parar e olhar, tudo é adverso. Os olhos são dispositivos de segurança na cidade, ainda mais aqui no Brasil, nunca estamos desarmados na cidade, são sempre espaços de passagem e não de paragem. Mas acho que temos que inventar formas de estar in visu e in situ - com o que se dá a partir do olho e com o que se dá sobre a terra. O Alain Roger, um historiador da arte, fala isso: como você olha para as grandes estradas, as autorroutes, como inventar formas de olhar para aquilo como paisagem, como uma composição? Eu acho que a paisagem é fragmento, uma coisa com a outra e com a outra que a gente vai tecendo. 
                    <ModalLink n={11}/>.
                    Eu acho que ela é imprescindível porque uma relação em paisagem com o espaço é uma relação de engajamento com esse espaço.   Como a gente com o sentido de paisagem nos olhos, como aceitamos uma W3 e uma série de espaços na cidade, no caso de Brasília, do jeito que estão? Que composição é essa que a gente quer?
                    </p>
                    <div className="green-box">
                    Como a gente consegue aceitar o ultraje ou como a gente o assimila?
                    </div>
                    <p>
                    Como a gente aceita como se fosse possível para os olhos olhar uma W3 Norte? Não falo de beleza, mas como, na própria desorganização, como podemos criar composições, possibilidades de pertencimentos, porque a paisagem é esse engajamento.
                    </p>
                </Row>
                <Row className="entrevistas-carousel-row">
                    <CarouselDefault images={images()} heigth={"700px"}/>
                </Row>
                <Row>
                    {
                        array15.map((v, i)=> (
                            <EntrevistaImageModal 
                                src={getImagePath(i+1)}
                                isOpen={modalControl[i]}
                                handleClose={handleClose(i)}
                                key={"modal-entrevista-"+i}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}