import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Verao(){
    let images = [
        {
            src: "assets/fotografia/verao/01_Dobal_palmeiras_mn.jpg",
            legenda: "Mais um nome ligado aos militares vai compor o alto escalão do Ministério da Educação"
        },
        {
            src: "assets/fotografia/verao/02_Dobal_palmeiras-4785_mn.jpg",
            legenda: "Bolsonaro assina decreto que facilita posse de armas"
        },
        {
            src: "assets/fotografia/verao/03_Dobal_palmeiras-4771_mn.jpg",
            legenda: "Vice-presidente declara fim da Lei de Acesso à Informação"
        },
        {
            src: "assets/fotografia/verao/04_Dobal_palmeiras-3_mn.jpg",
            legenda: "Governo Bolsonaro oficializa saída do Pacto Global para a Migração, adotado por mais de 160 países membros da ONU"
        },
        {
            src: "assets/fotografia/verao/05_Dobal_palmeiras-7022_mn.jpg",
            legenda: (<div>
                Operação prende suspeitos de envolvimento na morte de Marielle Franco. Há indícios de que detidos comandem Escritório do Crime,<br></br> braço armado de organização, especializado em assassinatos por encomenda
            </div>)
        },
        {
            src: "assets/fotografia/verao/06_Dobal_palmeiras-2_mn.jpg",
            legenda: "Flávio Bolsonaro empregou mãe e esposa de chefe do Escritório do Crime em seu gabinete"
        },
        {
            src: "assets/fotografia/verao/07_Dobal_palmeiras-6968_mn.jpg",
            legenda: "Em discursos, Jair Bolsonaro exaltou milícias e grupos de extermínio. Deputado Flávio Bolsonaro quer legalizar milícias no Rio"
        },
        {
            src: "assets/fotografia/verao/08_Dobal_palmeiras-7072_mn.jpg",
            legenda: "Flávio Bolsonaro homenageou no passado major da PM que foi mais um dos presos acusados de comandar milícia"
        },
        {
            src: "assets/fotografia/verao/09_untitled-7066_mn.jpg",
            legenda: (<div>
                Deputado Jean Wyllys abandona mandato cansado de perseguição e escolta obrigatória. Opositores divulgam que exílio seria por<br></br> envolvimento com corrupção e com Adélio Bispo, agressor do Presidente
            </div>)
        },
        {
            src: "assets/fotografia/verao/010_Dobal_palmeiras-7080_mn.jpg",
            legenda: <div>
                Mar de lama varre Brumadinho após rompimento da barragem. Até o momento <br></br>número de corpos encontrados são 110, desaparecidos chegam a 238
            </div>
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        na mídia
                    </div>
                    <div className="namidia-text-subtitle">
                        Notícias de Verão
                    </div>
                    <div className="namidia-maintext">
                        <p>
                            Verão é tempo de deleite do corpo, navegar nas notícias sobre as primeiras semanas do novo governo e divagar: e se a fotografia fosse menos literal e as imagens captassem mais radicalmente algo além dos fatos? Afinal, as imagens nunca são apenas literais, elas evocam sensações e percepções; já os fatos não são apenas fatos, são avaliados e interpretados. A percepção ocorre por meio de inúmeras associações, parece um desperdício reter apenas um significado superficialmente útil, um senso comum do que aparentemente é. E se fosse possível captar com uma câmera fotográfica uma fala subjacente do mundo? Perdemos tempo em corredores que levam apenas a associações redundantes entre imagens e palavras, quando outras conexões poderiam levar a uma contaminação de coisas e ideias em uma cacofonia mais frutífera.  
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            Notícias de Verão
                        </div>
                        <div>
                            Ensaio originalmente publicado na Revista Esferas, nº 13, 2018. 
                        </div>
                        <div>
                            https://portalrevistas.ucb.br/index.php/esf/article/view/10339 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}