import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Retratos(){
    let images = [
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/01RetratosFalantes_IMG_2840_VD.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/02RetratosFalantes_IMG_2770_JL_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/03RetratosFalantes_IMG_2747-UV_mn-mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/04RetratosFalantes_IMG_2334SR_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/05RetratosFalantes_T_A_P1080399_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/06RetratosFalantes_P1010679P_G_mn-mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/07RetratosFalantes_IMG_2977_WC-mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/02_Retratos falantes_10000px/08RetratosFalantes_IMG_3131_MG-mn.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        nas bocas
                    </div>
                    <div className="namidia-text-subtitle">
                    Retratos Falantes
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Essa série reúne imagens em que o retratado também se revela pela sua fala. Muitos desses retratos foram feitos a partir de conversas em uma mesa de bar, quando tentei preservar algo de quem estava à frente com a ajuda do celular e da memória. Procuro reter os fragmentos de conversa que possam ser representativos de cada pessoa, ou assuntos que pairam no ar e que merecem ser captados. A maioria dessas fotos foi feita com um iPhone; elas receberam tratamento posterior para alteração da imagem e inclusão do texto. Para o formato final, busquei um resultado que oscilasse entre a fotografia, o jornalismo, a literatura e a história em quadrinho.
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                    Série Retratos Falantes, 2011.   
                        </div>
                        <div>
                    Oito fotografias  - Impressão a jato de tinta sobre papel de algodão        
                        </div>
                        <div>
                    Dimensão: 51 x 68 cm.
                        </div>
                        <div>
                        Ensaio apresentado em exposição coletiva durante o Foto Rio 2011 no Centro de Arte Hélio Oiticica, no Rio de Janeiro, 2011 e, parcialmente, na exposição Diálogos da Resistência, no MAB, em Brasília, 2012. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}