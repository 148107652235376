import { useEffect } from "react";
import Header from "../../components/Header";
import TopBarImage from "../../components/TopBarImage";
import "../../css/screens/fotografia/NoMundo.css"
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NoMundo(){
    const navigate = useNavigate();
    
    function addImageAnimation(nometexto:string,  nomeimage:string){
        let image:any = document.querySelector(nomeimage);
        let text = document.querySelector(nometexto);
        
        text?.addEventListener('mouseover', ()=>{
            image.style.opacity = 1;
        })

        text?.addEventListener('mouseout', ()=>{
            image.style.opacity = 0;
        })
    }

    function addRedirect(element:string, src:string){
        let dv:any = document.querySelector(element);

        dv?.addEventListener('click', ()=>{
            navigate(src)
        })
    }

    useEffect(()=>{
        addImageAnimation('.namidia-text-mar', '.image-mar')
        addImageAnimation('.namidia-text-jardim', '.image-jardim')
        addImageAnimation('.namidia-text-branco', '.image-branco')
        addImageAnimation('.namidia-text-ecos', '.image-ecos')
        addImageAnimation('.namidia-text-paris', '.image-paris')
        addImageAnimation('.namidia-text-caminho', '.image-caminho')

        addRedirect('.namidia-text-caminho', '/site/fotografia/nomundo/caminho')
        addRedirect('.namidia-text-mar', '/site/fotografia/nomundo/mar')
        addRedirect('.namidia-text-jardim', '/site/fotografia/nomundo/jardim')
        addRedirect('.namidia-text-branco', '/site/fotografia/nomundo/branco')
        addRedirect('.namidia-text-ecos', '/site/fotografia/nomundo/ecos')
        addRedirect('.namidia-text-paris', '/site/fotografia/nomundo/paris')
    }, [])

    return <>
        <Header />
        <TopBarImage />
        <div className="namidia-mainContainer">
            <Container>
                <Row className="namidia-textoContainer">
                    <Col>
                        <Row className="namidia-text-namidia">
                            no mundo
                        </Row>
                        <Row className="namidia-text-caminho">
                            A caminho
                        </Row>
                        <Row className="namidia-text-mar">
                            Além mar
                        </Row>
                        <Row className="namidia-text-jardim">
                            JARDIM FRANCÊS
                        </Row>
                        <Row className="namidia-text-branco">
                            Em branco
                        </Row>
                        <Row className="namidia-text-ecos">
                            Ecos Parisienses
                        </Row>
                        <Row className="namidia-text-paris">
                            Dijon Paris Goiás
                        </Row>
                    </Col>
                    <Col>
                    <Row className="namidia-imageContainer">
                        <div className="image-caminho">
                            <img src="/assets/fotografia/02_No mundo/01_A Caminho_2013/02_DSC7597_turner_1000px_mn.jpg" />
                        </div>
                        <div className="image-mar">
                            <img src="/assets/fotografia/02_No mundo/03_Alem mar_2007/01Dobal_1000px.jpg" />
                        </div>
                        <div className="image-jardim">
                            <img src="/assets/fotografia/02_No mundo/04_Jardim Frances_2005/01_Jardim Francês-Africa_1000px.jpg" />
                        </div>
                        <div className="image-branco">
                            <img src="/assets/fotografia/02_No mundo/05_Em branco_2001/Dobal-EmBranco01-Esquema.jpg" />
                        </div>
                        <div className="image-ecos">
                            <img src="/assets/fotografia/02_No mundo/06_Ecos Parisienses_2013/Dobal_19_P1040217_mn.jpg" />
                        </div>
                        <div className="image-paris">
                            <img src="/assets/fotografia/02_No mundo/07_Paris Dijon Pires do Rio_2012/110-P1090109 Paris_mn.jpg" />
                        </div>
                    </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    </>
}