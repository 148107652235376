import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";


export default function Mar(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/01Dobal_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/02Dobal_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/03Dobal_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/04Dobal_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/05Dobal_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/06Dobal-IMG_2446galeria.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/03_Alem mar_2007/07Dobal.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                        Além-mar
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Ensaio fotográfico a partir de fotos realizadas em Portugal, com inserção de texto na imagem procurando explorar o fato de que a fotografia pode revelar algo que vai além do puramente visual. As pessoas de preto nas cidades pequenas, as igrejas onde explode o barroco, a sensualidade de um jardim, tudo remete à dor, ao fervor, ao ardor do fado lusitano, implícito. Uma viagem de uma brasileira à Portugal, no percurso inverso da ancestral trajetória colonial, leva não só à descoberta de novos territórios do outro lado do mar, mas a um reconhecimento, pois para onde vamos carregamos o mapa das nossas referências. A fotografia aliada ao texto testemunha aqui a fusão de novos cenários com antigos ecos e recentes associações. Ela serve de luneta contemporânea para o achamento de Portugal. 
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        Exposição individual (instalação com um fita e trechos de poemas do Fernando Pessoa nas paredes em letra adesiva. Espaço Cultural Renato Russo, Brasília, 2007. 
                        36 fotografias 20x30cm (agrupadas em três conjuntos) e 10 fotografias 50 x 75 cm. 
                        Cópias cromogênicas. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}