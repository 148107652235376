import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Love(){
    let images = [
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/01_Love Stories 03_pressentiram_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/02_Love_Stories_arvore_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/03_Love_Stories_adesivo_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/04_Love_Stories_bar_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/05_Love_Stories_transcendente_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/06_Love_Stories_aquario_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/07_Love_Stories_zebra_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/08_Love_Stories_copo_2001_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/09_Love Stories_02_galeria_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/10_Love_Stories_farelos_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/11_Love_Stories_Conic_1999_1000px.jpg",
            legenda: "Instalação Love Stories. Luminárias e textos bordados em tiras de tecido. Boate Millenium, no Conic. Brasília, 1999."
        },
        {
            src: "assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/12_Love_Stories_Expo_CAL_Bsb_2001_1000px.jpg",
            legenda: "Exposição Love Stories 2. Instalação com fotografias, duas cadeiras, pergaminho bordado. Galeria da Casa da América Latina (CAL), em Brasília, 2001. "
        },

    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        nas bocas
                    </div>
                    <div className="namidia-text-subtitle">
                        Love Stories
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        A série combina cartões postais de diversos tipos, de anúncios a obras de arte, e textos escritos por Susana Dobal a partir de histórias ouvidas e situações vividas. 

                        </p>
                        <p>
                        Imagens e texto foram cuidadosamente colecionados, combinados, editados, e fotografados para formar um pequeno catálogo de situações amorosas. Se as imagens dos postais passeiam soltas por mãos anônimas, as combinações de imagem e texto sugerem que elas fazem parte de um mesmo tecido imagético, o de uma cultura compartilhada cujo sentido ao mesmo tempo oscila e agrega as pessoas. A experiência dispersa nas bocas e nas falas alterna entre a fusão e a angústia amorosa. 
                        </p>
                        <p>
                        A série « Love Stories » fez parte de uma instalação apresentada na Galeria da Casa da América Latina (CAL), em Brasília, em outubro de 2001, que consistia em fotografia e bordado, combinando texto e imagem. Um diálogo acontecia entre duas cadeiras vazias cobertas por um longo pergaminho de 15 metros no qual foram bordados textos relacionados a cenas amorosas, mas não necessariamente românticas. Nas paredes, havia uma série de quarenta fotografias.
                        </p>
                        <p>
                        A instalação Love Stories II apresentada na CAL era uma continuação de uma exposição realizada na boate Millenium no CONIC, em Brasília, em dezembro de 1999. No projeto da CAL, perdeu-se um pouco da ironia dos textos bordados sobre a mesa em meio ao show de strip-tease da programação da boate, mas ganhou-se algo com a solenidade da galeria, o silêncio das cadeiras vazias, as fotos antes ausentes. O ensaio foi parcialmente publicado na Revista Studium (UNICAMP), n. 9, outono 2002. https://www.studium.iar.unicamp.br/nove/8.html 
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            Série Love Stories, 2001.
                        </div>
                        <div>
                            40 fotografias em preto e branco. Impressão em Sais de Prata. 
                        </div>
                        <div>
                            Dimensões diversas.
                        </div>
                        <div>
                            Exposição individual na Casa da América Latina, em Brasília, 2001.
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}