import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";
import { CarouselLink } from "../../../components/MainCarousel";


export default function Paris(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/07_Paris Dijon Pires do Rio_2012/110-P1090109 Paris_mn.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                    Paris Dijon Pires do Rio
                    </div>
                    <div className="namidia-maintext">
                        <p>

                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        Série fotográfica publicada em blog (fotoescritas) que mantive para experimentações com narrativas utilizando fotografia e texto. 2012.  
                        </div>
                        <div>
                            <CarouselLink 
                                src={"http://fotoescritas.blogspot.com/search/label/10%20-%20Paris%20Dijon%20Pires%20do%20Rio%20-%2001"}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}