import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Sala(){
    let images = [
        {
            src: "assets/fotografia/sala/01_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/02_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/03_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/04_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/05_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/06_A_Sala 1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/07_A_Sala_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/sala/00_A_Sala_esquema.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        na mídia
                    </div>
                    <div className="namidia-text-subtitle">
                        A Sala
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        A Sala faz parte de um conjunto de três grupos de imagens que exploram a televisão como um veículo que traz o mundo de fora para o território doméstico. Do anúncio de inseticida (Brooklyn, NY) à Copa do Mundo (Ritual) ou às notícias sobre a guerra que afeta o tabuleiro internacional (A Sala), as imagens exploram a televisão como janela que transforma o espaço público em história íntima. Na sala do apartamento, as notícias se abrigam tanto nos objetos ao redor que forram o ninho doméstico quanto nos corpos que se unem. 
                        </p>
                        <p>
                        Exposição coletiva divulgando artistas do Centro-Oeste com curadoria de Cristina Rosa. Wisconsin Union Galleries, University of Wisconsin, Madison. 2006.
                        </p>
                        <p>
                        Trabalhos apresentados: "A Sala" (2002),  "Brooklyn,NY" (1998), "Ritual" (2002) - três conjuntos de fotos tendo como tema a televisão. Tamanhos variados. Cópias cromógenas. A Sala também foi apresentada na exposição Melhor de três: Três Tempos - Elyezer Szturm, Evandro Salles, Susana Dobal (2006). Casa da Cultura da América Latina, como parte da série Tempo no Espaço. 
                        </p>
                    </div>
                    <div className="namidia-subtext">

                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}