import React, { useState } from 'react';
import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/texto/Entrevistas.css";
import CarouselDefault from "../../../components/CarouselDefault";
import EntrevistaImageModal from "../../../components/EntrevistaImageModal";



export default function AndreReouille(){
    const array15 = Array(15).fill(false);

    /*** Modal Control  */
    const [modalControl, setModalControl] = useState(Array(15).fill(false));

    const handleClose = (n:number) => () => {let m = modalControl.slice(); m[n]=false; setModalControl(m);}
    const handleOpen = (n:number) => () => {let m = modalControl.slice(); m[n]=true; setModalControl(m);}
    const ModalLink = (props:any) => (
        <a onClick={handleOpen(props.n)} className='modal-link'>
            <b>
                &nbsp;[fig. {props.n+1}]
            </b>
        </a>
    );

    /*** Image control */
    function getImagePath(n:number){
        return  "assets/texto/entrevistas/05_Andre Rouille/Imagens_Entrevista_Rouille/" + 
        n.toString().padStart(2, '0') + 
        "Dobal_IMG.jpg"
    }

    function generateSrc(index:any){
        return {
            src: getImagePath(index),
            legenda: (<>
                <b>Figura {index}</b>
            </>)
        }
    }

    let images = () => new Array(15).fill(0).map((e, idc)=>{
        return generateSrc(idc + 1);
    })

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="entrevista-mainBody">
                <Row className="entrevista-titulo">
                    <b>
                        Foto-evento: Entrevista com André Rouillé - Susana Dobal
                    </b> 
                </Row>
                <Row>
                    <div className="entrevista-sub-text">
                    Entrevista originalmente publicada na Revista de Estudos Brasileños, Universidad de Salamanca e USP, v. 4, n.8, p. 177-189, jul-dez 2017.
                    
                    </div>
                    <div className="entrevista-sub-text">
                        <a>
                        http://www.studium.iar.unicamp.br/31/2.html
                        </a>
                    </div>
                    <div className="entrevista-sub-text">
                    Entrevista e fotos Susana Dobal 
                    </div>
                </Row>
                <Row className="entrevista-texto">
                    <p>
                        Autor de sete obras sobre fotografia, a mais recente traduzida no Brasil no ano passado, A fotografia : entre documento e arte contemporânea. (São Paulo, Ed. Senac,2009), André Rouillé é professor na Universidade Paris 8 e diretor do site de arte contemporânea paris-art, onde escreve editoriais semanais. Seu livro apresenta a fotografia como produto de um contexto maior e questiona boa parte da reflexão sobre a fotografia por ela ater-se à questão do referente e do índice, deixando portanto de considerá-la como uma linguagem inserida na história. A apropriação da fotografia pela arte contemporânea, a história da fotografia, as teorias sobre ela e a filosofia são as referências com as quais o autor dialoga para identificar como se deu a transição da fotografia como documento para a fotografia como escrita.
                    </p>
                    <p>
                        A entrevista ocorreu em dois momentos. Em 2009, em Paris, um pouco antes de seu livro ser lançado no Brasil, foi realizada a entrevista apresentada logo abaixo. Em 2010, André Rouillé veio ao Brasil a convite da Universidade de Brasília, onde deu um curso,  depois esteve no Rio de Janeiro onde participou de eventos na Cândido Mendes e na Galeria da Gávea, e em Belo Horizonte onde participou de um colóquio na UFMG. Em camada menos evidente, nos eventuais links dessa entrevista, estão frases soltas que foram sendo recolhidas ao longo de parte desse percurso no Brasil. Elas apontam para outras maneiras de conhecer o autor : fragmentos que revelam a sua escrita, os autores que o informam, as falas soltas que involuntariamente davam-lhe um contorno. As fotos dos links aparecem em sua evocativa precariedade, ao mesmo tempo insuficientes e sugestivas, querendo se aproximar do que no seu livro o autor chama de eventos
                        <ModalLink n={0}/>.
                    </p>
                    <div className="green-box">
                        Você tem um percurso curioso. Depois de ter se dedicado à fotografia do século XIX com diversas publicações sobre esse assunto, hoje dirige um site dedicado à arte contemporânea (www.paris-art.com ) que é muito atento ao momento presente, seja à criação artística seja às reverberações da política e da vida social no domínio artístico - assuntos como a imigração ou a redução da ação cultural francesa no exterior são alguns exemplos. Como se passou essa mudança?
                    </div>
                    <p>
                        Eu fiz minha tese sobre a fotografia do século XIX e fui a primeira pessoa na França a ter uma tese de doutorado sobre a fotografia. Foi em 1980. Antes eu fui professor de matemática, por treze anos. Eu gosto muito de mudanças 
                        <ModalLink n={1}/>.
                        Na época não havia muita reflexão sobre a fotografia, sobretudo sobre a fotografia histórica. Eu me interessei pela seguinte questão: por que a fotografia apareceu no século XIX? Dizia-se então que foi Daguerre e Nièpce que inventaram a fotografia, mas além disso eu queria saber por que em um determinado momento da história ocorreu o nascimento de um novo tipo de imagem. Trabalhando muito com textos do século XIX, que depois originaram o livro La photographie en France: Textes et Controverses,  mostrei que a fotografia era a imagem que a sociedade industrial tinha necessidade para funcionar segundo seus próprios paradigmas, ou seja, a sociedade industrial é o advento da máquina, com processos físicos e químicos atuando no conjunto dos principais processos de produção. Esses processos vêm concorrer e depois substituir ações feitas pela mão e pelo artesanato. No domínio da arte e das imagens em geral, as ilustrações e as imagens eram manuais. A fotografia foi a primeira imagem na história que era inteiramente mecânica e química e em um momento dado em que a produção de bens ordinários era ao mesmo tempo mecânica e química. Mostrei então que a fotografia era um produto e também um dos instrumentos da sociedade industrial. Cada sociedade em um momento da sua história precisa de uma imagem que seja coerente com seu funcionamento. Escrevi sete livros sobre o século XIX e um dia eu me dei um basta, porque na vida eu era professor de matemática e parei, eu me especializei no século XIX e parei 
                        <ModalLink n={2}/>.
                        Fui editor de uma revista de fotografia por dez anos, La Recherche Photographique, financiada pela cidade de Paris, e aprendi muita coisa com isso. A revista parou por razões diversas e era um momento em que a internet balbuciava. Eu gosto muito de escrever, adoro produzir e senti que a internet era o instrumento da sociedade pós-industrial, a sociedade da informação. A internet tem a mesma importância, a mesma necessidade que teve a fotografia em 1850. Então eu mergulhei nesse domínio e criei o site paris-art.com em 2002 
                        <ModalLink n={3}/>. 
                        Hoje estamos bem situados, mas trabalhamos muito para isso. Atualmente temos cinquenta colaboradores que escrevem no paris-art para cobrir na França toda a arte contemporânea, a fotografia, o vídeo, a dança, o design e o livro. Agora estamos entrando na internet móvel e nas redes sociais - facebook, myspace etc.
                    </p>
                    <div className="green-box"> 
                        Apesar de um interesse crescente, parece que há um mal-estar no meio acadêmico no que diz respeito à fotografia, principalmente entre os que se dedicam ao cinema, você concorda?
                    </div>
                    <p>
                        Curiosamente, a fotografia é um assunto muito complicado. Uma prática que todos conhecem, há a larga difusão da fotografia digital - estou preparando um livro sobre isso - ela é também muito difundida no pensamento. No meu livro La Photographie [A fotografia : entre documento e arte contemporânea ] fui severo contra alguém que na França é muito importante, Roland Barthes. Não vamos questionar a capacidade intelectual dele, mas Roland Barthes disse besteiras impensáveis sobre a fotografia.  Ele disse que ela não tem código ou que na fotografia ele via apenas “a coisa” esquecendo assim de ver a escrita. Para ele a fotografia é a marca direta da coisa sem mediação. Ele se esquece que o menor enquadramento é já um posicionamento ideológico sobre as coisas 
                        <ModalLink n={4}/>.
                        As pessoas dizem no rádio, em todo lugar, que a fotografia faz uma constatação do real. No cinema há todo um trabalho mais aparente e na fotografia parece ser apenas um clique e pronto. Há um automatismo aparente tal que ela sofre pelo impensado. Com a fotografia digital há uma mudança radical, uma mudança da natureza e as pessoas lúcidas dizem absurdos como a fotografia digital é o mesmo que a fotografia do século XIX só que digital, quando o processo é inteiramente diferente 
                        <ModalLink n={5}/>.
                        Como é um objeto cotidiano, todos se sentem no direito de escrever sobre ela. Barthes não conhecia nada de fotografia, ele conhecia fotos de família ou as fotografia comentadas na revista Nouvel Observateur que tinha um suplemento chamado “Photo”. Barthes não tinha cultura fotográfica ainda que fosse excessivamente cultivado sobre outros assuntos. Ele desconhecia totalmente obras fotográficas mas se autorizava a escrever. E há outros exemplos assim de intelectuais franceses que fazem a mesma coisa, mas não fariam esses julgamentos definitivos sobre a literatura, o cinema, a ópera, mas sobre a fotografia sim, não se conhece nada e se escreve assim mesmo. Regis Debray escreveu um livro que se chama L'oeil naïf - no texto não é apenas o olho que é ingênuo, o texto é de uma ingenuidade atroz.
                    </p>
                    <div className="green-box">
                        E no entanto Barthes está ainda bem presente no pensamento sobre a fotografia, ele é uma referência embora haja autores que lhe façam oposição.
                    </div>
                    <p>
                        Ele não vê o trabalho fotográfico, ele não vê que a imagem fotográfica é uma construção, ele crê que ela é um registro. Mesmo antes dele, com Peirce, a abordagem gira em torno do índice, da marca. A fotografia não é a verdade, é um regime de verdade. Ela é um índice mas não apenas um índice. Barthes ressalta ainda o “isso foi” – há um ser que existe e que a fotografia apenas registra esse ser. Trata-se da filosofia tradicional, a imagem é cópia, uma falsa cópia, conhecemos bem toda essa ideia filosófica. Não é nada disso. A fotografia não registra coisas pré-existentes, ela faz ser alguma coisa 
                        <ModalLink n={6}/>.
                        O ser fotográfico é o  produto de um procedimento, de uma visão, de uma técnica, de uma estética e de uma ideologia. Não há o ser que apenas registramos. Não há coisa pré-existente, as coisas são materialmente necessárias mas não é, por exemplo, a mesa que está na fotografia  mas a mesa enquanto material. É claro que há objetos, mas não são os objetos que vêm enquanto tais para a imagem. Os objetos, a paisagem, as coisas, os móveis, os corpos são materiais estéticos. O que é materialmente necessário para que as fotos existam; porém não é a mesa que está na imagem, mas a mesa traduzida por uma olhar, uma técnica, uma estética etc. Meu desacordo com Barthes não é uma mera oposição, é uma diferença filosófica total sobre a maneira como pensamos as imagens. 
                    </p>
                    <div className="green-box">
                        No seu livro você critica não apenas Barthes mas também Henri-Cartier Bresson outra referência francesa fundamental para a história da fotografia, um a nível teórico e outro enquanto fotógrafo. Por que eles não podem mais guiar nosso olhar para a fotografia?
                    </div>
                    <p>
                        A noção ingênua de crer que as coisas vêm se imprimir diretamente sobre a imagem tornou-se inviável. Há trabalhos de teóricos e filósofos que questionam isso e temos imagens agora, imagens digitais que estão sempre em um devir, em processos que nos obrigam a ver que as coisas se entrecruzam, que o real é mais complexo. O mundo e as novas tecnologias fazem com que não acreditemos mais nas coisas como antes. Temos uma visão mais relativa sobre o devir, a mistura, a transformação,  não há mais o verdadeiro e o falso 
                        <ModalLink n={7}/>.
                    </p>
                    <div className="green-box">
                        Mesmo o momento decisivo tornou-se impossível.
                    </div>
                    <p>
                        Tudo isso é muito platônico, o pensamento de Barthes é muito platônico no sentido de que há o ser e que a fotografia é a cópia . A fotografia não registra sem construir. Reabilitamos assim o trabalho fotográfico, o papel da tecnologia, o trabalho estético, tudo o que Barthes não vê. E o instante decisivo crê que o movimento no mundo evolui e há um ápice no qual tudo se resume e o sentido aparece, haveria uma verdade que seria um ponto único, um concentrado de verdade apreensível por um ser capacitado para isso, que para Cartier-Bresson é um fotógrafo. Essa é uma ideia filosoficamente falsa. Vejamos uma coisa simples como o “isso foi” de Barthes : sim se eu te fotografei foi porque você estava lá, mas não é você que está na foto, você é apenas um dos elementos que constitui a imagem, eu estou tanto na imagem quanto você, quanto a tecnologia etc 
                        <ModalLink n={8}/>.
                        Ele crê que há apenas o modelo na imagem. Isso recoloca em questão a noção de modelo. Chegamos assim a outra filosofia, a pessoas como Deleuze que falam do fim do platonismo. Barthes e Cartier-Bresson estavam no pensamento que era muito platônico enquanto há uma outra filosofia que reflete mais sobre o devir do que sobre a procura de uma essência. O “isso foi” refere-se à essência da fotografia.
                    </p>
                    <div className="green-box">
                        Gilles Deleuze tem forte presença no seu livro, mesmo quando ele não é mencionado. Como Deleuze, que não falou muito sobre a fotografia,  chega a oferecer as bases para pensá-la?
                    </div>
                    <p>
                        Ele quase não fala da fotografia, mas fala sobre questões importantes como o tempo ou como em Lógica da Sensação, livro em que ele trata da obra do pintor Francis Bacon, quando ele diz que o problema do pintor não é partir de uma tela branca, mas como esvaziá-la 
                        <ModalLink n={9}/>.
                        Ele diz que a tela é plena de tudo o que ele já viu, suas práticas. No caso do fotógrafo, o visor dele está pleno de todas as milhares de imagens que ele carrega consigo. Deleuze é importante porque dá uma compreensão das coisas pós-platônicas que, no meu ponto de vista, é o que estamos vivendo atualmente.
                    </p>
                    <div className="green-box">
                        Isso lembra também outras referências importantes do livro, Henri Bergson e também Foucault. Foucault pela reflexão sobre a representação e os mecanismos de controle e Bergson pelo seu estudo da percepção aliada à memória. Pode-se dizer que a filosofia foi a sua referência maior para pensar a fotografia?
                    </div>
                    <p>
                        Eu conhecia o livro de Barthes de cor, quase todo de cor. O livro dele saiu em 1980. Foi uma referência importante. No meio da fotografia as pessoas gostam das coisas simples, e Barthes foi simples nesse livro. Pensando a fotografia, eu me dei conta de que o que ele dizia não funcionava.  Quando se quer ter um pensamento mais sutil e aprofundado, vê-se logo que esse texto não funciona. Eu precisava de um trabalho de pesquisa teórica para poder pensar as imagens. Deleuze e esses autores me deram isso.
                    </p>
                    <div className="green-box">
                        Ainda que ele não tenha escrito sobre a fotografia, talvez porque tivesse o algum preconceito contra ela.
                    </div>
                    <p>
                        O que é a filosofia? A filosofia é uma ferramenta que nos permite pensar. Foucault desenvolveu muito a noção de regime de verdade. Não há verdade dada, há regimes de verdade 
                        <ModalLink n={10}/>.
                        O que se passa entre a fotografia e o desenho no sec XIX é que ela vem dar outro regime de verdade onde o homem tem uma posição diferente. O regime de verdade da fotografia digital é completamente diferente do regime de verdade da fotografia analógica e posso dizer isso porque tenho o conceito do regime de verdade para pensar qual é a crença que o sustenta. Eu leio muito a filosofia, mas para encontrar as ferramentas capazes de me fazer compreender meu assunto, no momento, a internet. 
                    </p>
                    <div className="green-box">
                        Você demonstra no seu livro que o humanismo na fotografia tornou-se impossível por causa do esgotamento das narrativas universais modernas. O humanismo tão ligado ao fotojornalismo não teria adquirido outras configurações como o interesse pelo outro, pelo diálogo, situações de inversão do sujeito que passa produzir a imagem?
                    </div>
                    <p>
                        O humanismo é muito ligado à reportagem. Foi um movimento que está desaparecendo de forma radical, ele nasceu depois da segunda guerra mundial. O homem abstrato não é social, é uma abstração. Ele não é determinado pela história, é o homem em si, mas não há homem em si. Hoje vemos que essa abstração é ideológica. Os grandes relatos da fraternidade e da igualdade foram abalados a partir dos anos 1980.
                    </p>
                    <div className="green-box">
                        Barthes estava ciente disso, ele criticou as ideias gerais do humanismo na resenha sobre a exposição A grande família dos homens.
                    </div>
                    <p>
                        Ele criticou porque nessa época já se começava a questionar isso. O humanismo crê que o homem no sentido amplo tirando todas as diferenças  é o ator da história, o ator do mundo. Por isso o repórter fotográfico é importante. Ele considera que o homem é o ator do que se passa, é ele quem conduz o seu destino, inclusive histórico. Fiz uma análise das imagens fotográficas apontando a diferença entre a fotografia humanista e a fotografia humanitária. Na fotografia humanitária o homem não é mais ator, ele não é mais ativo, há vítimas, o homem é vítima da AIDS, do desemprego etc. Em Paris, quando vemos homens nas calçadas pedindo dinheiro vê-se que tornaram-se como lixo na calçada, reduzidos a tal ponto que o humanismo só pode levar um golpe 
                        <ModalLink n={11}/>.
                        Pessoas como Cartier-Bresson que estavam presentes no instante decisivo são pessoas que agem. Pessoas que têm AIDS, que estão na calçada, não têm o que comer, os sem-teto, os excluídos de tudo, não estão no momento decisivo, eles estão na duração. 
                    </p>
                    <div className="green-box">
                        Mas há ainda certo humanismo na empatia, no diálogo, no interesse por essas pessoas.
                    </div>
                    <p>
                        É mais uma compaixão do que um diálogo.  Se sou fotógrafo na tradição humanista o modelo é igual a mim. Quando fotografo alguém que vai morrer ou que não tem casa e dificilmente vai sair dessa ele está deitado e eu estou em pé e isso muda tudo. Martin Parr, por exemplo, age no fim do humanismo. Ele fotografa pessoas em ação mas em posição desfavorável que os desvaloriza, pessoas comendo, por exemplo, é uma situação em que as pessoas dificilmente estão bem 
                        <ModalLink n={12}/>.
                        Ele mostra o lado quase bestial do homem.
                    </p>
                    <div className="green-box">
                        Quais são os desafios que se apresentam hoje àqueles que trabalham com a fotografia, sejam fotógrafos ou teóricos ?
                    </div>
                    <p>
                        Hoje há uma grande questão que é a fotografia digital, que será o assunto do meu próximo livro. A fotografia que funcionava no domínio da mecânica e da química terminou. Nem sei se podemos comprar um câmera analógica na Fnac. Foi tão rápido quanto o celular e a internet. O essencial da fotografia é feito agora com o telefone. Sempre haverá quem faça fotografia analógica, nada desaparece. Mas há outras práticas que avançam.
                    </p>
                    <div className="green-box">
                        E qual será a  grande questão do seu livro?
                    </div>
                    <p>
                        Assim como a fotografia analógica foi necessária para a sociedade industrial, vou investigar quais são os dispositivos que obrigam a fotografia a ser digital. Ela é ligada ao computador, à rede, a um regime de verdade totalmente diferente 
                        <ModalLink n={13}/>.
                        Ela é sempre já retocada enquanto que na fotografia do Henri Cartier-Bresson, um caso caricatural, havia a margem preta para mostrar que ela não foi nem mesmo reenquadrada, retocar ou reenquadrar era crime contra a verdade. Retocar antes era antes um trabalho especializado. Agora toda imagem passa por um tratamento obrigatório com um software apropriado. O retoque não é exterior à foto. Antes tínhamos a ilusão da verdade imutável, agora temos a certeza de algo que é sempre já retocado mesmo que não queiramos. Isso muda tudo. Há ainda a velocidade de circulação pela rede, a superfície de inscrição que não é mais o papel, mas a tela do computador. Os novos aparelhos não têm visor 
                        <ModalLink n={14}/>.
                        O visor é herança da Renascença, do humanismo, do ponto de vista humano e único. Isso desaparece, não há mais olho, vamos de tela em tela. Vivemos no tempo das telas. 
                    </p>
                </Row>
                <Row className="entrevistas-carousel-row">
                    <CarouselDefault images={images()} heigth={"700px"}/>
                </Row>
                <Row>
                    {
                        array15.map((v, i)=> (
                            <EntrevistaImageModal 
                                src={getImagePath(i+1)}
                                isOpen={modalControl[i]}
                                handleClose={handleClose(i)}
                                key={"modal-entrevista-"+i}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}