
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "../../css/screens/texto/Entrevistas.css"
import TopBarImage2 from "../../components/TopBarImage2";
import { CarouselLink } from "../../components/MainCarousel";
import CarouselEntrevistas from "../../components/CarouselEntrevistas";

export default function Entrevistas(){
    let images = [
        {
            src: "assets/texto/entrevistas/paginaabertura/01_Karina_Dias_2017_2156_mn.jpg",
            link: "/site/texto/entrevistas/karinadias" ,
            legenda: (<>
                <div style={{height: "10px"}}></div>
                <h5>
                    Karina Dias
                </h5>
            </>)
        },
        {
            src: "assets/texto/entrevistas/paginaabertura/02_Dubois_08_2016_Fortaleza-1200367.jpg",
            link: "/site/texto/entrevistas/phillipedubois" ,
            legenda: (<>
                <div style={{height: "10px"}}></div>
                <h5>
                    Philippe Dubois
                </h5>
            </>)
        },
        {
            src: "assets/texto/entrevistas/paginaabertura/Tiago Santana_08_2016_Bsb-2-6_2017.jpg",
            link: "/site/texto/entrevistas/tiagosantana" ,
            legenda: (<>
                <div style={{height: "10px"}}></div>
                <h5>
                    Tiago Santana
                </h5>
            </>)
        },
        {
            src: "assets/texto/entrevistas/paginaabertura/Melanie_Einzig_0260_2010_02.jpg",
            link: "/site/texto/entrevistas/melanieeinzig" ,
            legenda: (<>
                <div style={{height: "10px"}}></div>
                <h5>
                Melanie Einzig
                </h5>
            </>)
        },
        {
            src: "assets/texto/entrevistas/paginaabertura/Andre_Rouille_DSC_0544_2009.jpg",
            link: "/site/texto/entrevistas/andrerouille" ,
            legenda: (<>
                <div style={{height: "10px"}}></div>
                <h5>
                    André Rouillé
                </h5>
            </>)
        },
    ]
    
    return <div className="page-container">
        <Header />
        <TopBarImage2 />
        <Container className="page-container page-height">
            <h3 className="page-title">entrevistas</h3>
            <div className="mundo-text-text1">
                <p>
                Entrevistas realizadas com teóricos e fotógrafos acompanhadas de um ensaio fotográfico que desenvolve uma narrativa paralela complementar ao texto. Elas foram originalmente publicadas em diferentes revistas acadêmicas, sempre acompanhadas das imagens legendadas, que aparecem como links ao longo da entrevista – os links estão assinalados como fig. 1, fig.2 etc. Ao desenvolver o ensaio, procuro utilizar a fotografia em entrevistas de uma forma menos literal, ou seja, menos comprometida em mostrar a aparência do personagem entrevistado do que em produzir eco para as suas ideias, sejam elas ideias verbais ou ideias visuais.  
                </p>
            </div>
            <br></br>
            <CarouselEntrevistas images={images}/>
        </Container>
        <Footer />
    </div>
}