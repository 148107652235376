import React from 'react';
import logo from './logo.svg';
import './App.css';
import InDevelopment from "./screens/InDevelopment";
import {Route, BrowserRouter, Routes, HashRouter} from "react-router-dom";
import Index from "./screens/Index";
import NaMidia from './screens/fotografia/NaMidia';
import Mundo from './screens/fotografia/na_midia/Mundo';
import Bio from './screens/Bio';
import Livros from './screens/texto/Livros';
import Artigos from './screens/texto/Artigos';
import Cv from './screens/bio/Cv';
import Entrevistas from './screens/texto/Entrevistas';
import AndreReouille from './screens/texto/entrevista/AndreReouille';
import KarinaDias from './screens/texto/entrevista/KarinaDias';
import PhillippeDubois from './screens/texto/entrevista/PhillippeDubois';
import TiagoSantana from './screens/texto/entrevista/TiagoSantana';
import MelanieEinzig from './screens/texto/entrevista/MelanieEinzig';
import Verao from './screens/fotografia/na_midia/Verao';
import Vento from './screens/fotografia/na_midia/Vento';
import Glossario from './screens/fotografia/na_midia/Glossario';
import Sala from './screens/fotografia/na_midia/Sala';
import NoMundo from './screens/fotografia/NaMundo';
import Caminho from './screens/fotografia/no_mundo/Caminho';
import Deslocamento from './screens/fotografia/no_mundo/Deslocamento';
import Mar from './screens/fotografia/no_mundo/Mar';
import Jardim from './screens/fotografia/no_mundo/Jardim';
import Branco from './screens/fotografia/no_mundo/Branco';
import Ecos from './screens/fotografia/no_mundo/Ecos';
import Paris from './screens/fotografia/no_mundo/Paris';
import NasBocas from './screens/fotografia/NasBocas';
import Retratos from './screens/fotografia/nas_bocas/Retratos';
import Love from './screens/fotografia/nas_bocas/Love';
import Vozes from './screens/fotografia/nas_bocas/Vozes';
import Contato from './screens/bio/Contato';
import CartaProibida from './screens/texto/CartaProibida';


function App() {
  return (

      <div className="App">

        <HashRouter>
          <Routes>
            {/*<Route*/}
            {/*    path="/"*/}
            {/*    element={<InDevelopment/>}*/}
            {/*/>*/}

            <Route
                path="/"
                element={<Index/>}
            />

            <Route
                path="/site/fotografia/namidia"
                element={<NaMidia/>}
            />
            { /* midia Sub Paginas */ }
                <Route
                    path='/site/fotografia/namidia/mundo'
                    element={<Mundo/>}
                />
                <Route
                    path='/site/fotografia/namidia/verao'
                    element={<Verao/>}
                />
                <Route
                    path='/site/fotografia/namidia/vento'
                    element={<Vento/>}
                />
                <Route
                    path='/site/fotografia/namidia/glossario'
                    element={<Glossario/>}
                />
                <Route
                    path='/site/fotografia/namidia/sala'
                    element={<Sala/>}
                />

            <Route
                path="/site/fotografia/nomundo"
                element={<NoMundo/>}
            />
            { /* no mundo Sub Paginas */ }
                <Route
                    path="/site/fotografia/nomundo/caminho"
                    element={<Caminho/>}
                />
                <Route
                    path="/site/fotografia/nomundo/mar"
                    element={<Mar/>}
                />
                <Route
                    path="/site/fotografia/nomundo/jardim"
                    element={<Jardim/>}
                />
                <Route
                    path="/site/fotografia/nomundo/branco"
                    element={<Branco/>}
                />
                <Route
                    path="/site/fotografia/nomundo/ecos"
                    element={<Ecos/>}
                />
                <Route
                    path="/site/fotografia/nomundo/paris"
                    element={<Paris/>}
                />

           <Route
                path="/site/fotografia/nasbocas"
                element={<NasBocas/>}
            />
            { /* no mundo Sub Paginas */ }  
                <Route
                    path="/site/fotografia/nasbocas/deslocamento"
                    element={<Deslocamento/>}
                />
                <Route
                    path="/site/fotografia/nasbocas/retratos"
                    element={<Retratos/>}
                />
               <Route
                    path="/site/fotografia/nasbocas/love"
                    element={<Love/>}
                />
                <Route
                    path="/site/fotografia/nasbocas/vozes"
                    element={<Vozes/>}
                />

            <Route
                path='/site/bio/minibio'
                element={<Bio/>}
            />

            <Route
                path='/site/bio/cv'
                element={<Cv/>}
            />

            <Route
                path='/site/bio/Contato'
                element={<Contato/>}
            />

            <Route
                path='/site/texto/livros'
                element={<Livros/>}
            />

            <Route
                path='/site/texto/artigos'
                element={<Artigos/>}
            />
            <Route
                path='/site/texto/artigos/cartaproibida'
                element={<CartaProibida/>}
            />

            <Route
                path='/site/texto/entrevistas'
                element={<Entrevistas/>}
            />
            {/* ----- Entrevistas ----- */}
                <Route
                    path='/site/texto/entrevistas/andrerouille'
                    element={<AndreReouille/>}
                />
                <Route
                    path='/site/texto/entrevistas/karinadias'
                    element={<KarinaDias/>}
                />
                <Route
                    path='/site/texto/entrevistas/phillipedubois'
                    element={<PhillippeDubois/>}
                />
                <Route
                    path='/site/texto/entrevistas/tiagosantana'
                    element={<TiagoSantana/>}
                />
                <Route
                    path='/site/texto/entrevistas/melanieeinzig'
                    element={<MelanieEinzig/>}
                />
          </Routes>
        </HashRouter>
      </div>
  );
}

export default App;
