
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Slider from "react-slick";

import "../../css/screens/texto/Livros.css"
import TopBarImage2 from "../../components/TopBarImage2";
import CarouselDefault from "../../components/CarouselDefault";
import { CarouselLink } from "../../components/MainCarousel";

export default function Livro(){
    let images = [
        {
            src: "assets/texto/livros/Dobal-capa.jpg",
            legenda: (<>
                <b>Peter Greenaway and the Baroque</b>: 
                writing puzzles with images. Berlin, LAP, 2010. 
                <br />
                <CarouselLink 
                    src="https://www.amazon.com/Peter-Greenaway-Baroque-Writing-puzzles/dp/3838327993" 
                />
            </>)
        },
        {
            src: "assets/texto/livros/Capa_Paisagem.jpg",
            legenda: (<> 
                <b>A Paisagem como narrativa</b>:
                quando a imagem inventa o espaço. Susana Dobal e Rafael Castanheira (orgs.). Brasília: Editora FAC Livros, 2022. 
                <br />
                <CarouselLink src="https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/ " />
            </>)
        },
        {
            src: "assets/texto/livros/Capa_Fotografia.jpg",
            legenda: (<>
                <b>Fotografia Contemporânea</b>: 
                fronteiras e transgressões. Susana Dobal e Osmar Gonçalves (orgs.). Brasília: Casa das Musas, 2013.
                <br />
                <CarouselLink 
                    src="https://issuu.com/paisagemunb/docs/fotografia_contempor_nea_-_fronteiras_e_transgress" 
                />
            </>)
        },
    ]
    
    return <div className="page-container">
        <Header />
        <TopBarImage2 />
        <Container className="page-container page-height">
            <h4 className="page-title">livros</h4>
            <CarouselDefault images={images}/>
        </Container>
        <Footer />
    </div>
}