
import Slider from "react-slick";
import "../css/components/CarouselDefault.css"

export default function CarouselDefault(props:any){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true
    };
    
    return <div className="carousel-wrapper">
        <Slider {...settings}>
            {props.images.map((image:any)=>(
                <div  className="slide">
                    <img 
                        src={image.src} 
                        alt="Imagem 1"  
                        style={{ display: "block", margin: "0 auto", height: props.heigth || "500px" }} 
                    />
                    <div className="legend">
                        <p>
                            {image.legenda}
                        </p>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
}