import React, { useState } from 'react';
import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/texto/Entrevistas.css";
import CarouselDefault from "../../../components/CarouselDefault";
import EntrevistaImageModal from "../../../components/EntrevistaImageModal";



export default function MelanieEinzig(){
    const array15 = Array(20).fill(false);

    /*** Modal Control  */
    const [modalControl, setModalControl] = useState(Array(20).fill(false));

    const handleClose = (n:number) => () => {let m = modalControl.slice(); m[n]=false; setModalControl(m);}
    const handleOpen = (n:number) => () => {let m = modalControl.slice(); m[n]=true; setModalControl(m);}
    const ModalLink = (props:any) => (
        <a onClick={handleOpen(props.n)} className='modal-link'>
            <b>
                &nbsp;[fig. {props.n+1}]
            </b>
        </a>
    );

    /*** Image control */
    function getImagePath(n:number){
        return  "assets/texto/entrevistas/04_Melanie Einzig/Imagens_Entrevista_Melanie_Einzig/" + 
        n.toString().padStart(2, '0') + 
        "_Melanie_Einzig_pt_mn.jpg"
    }

    function generateSrc(index:any){
        return {
            src: getImagePath(index),
            legenda: (<>
                <b>Figura {index}</b>
            </>)
        }
    }

    let images = () => new Array(20).fill(0).map((e, idc)=>{
        return generateSrc(idc + 1);
    })

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="entrevista-mainBody">
                <Row className="entrevista-titulo">
                    <b>
                        Melanie Einzig: Ironia e melancolia nas ruas de New York  
                    </b> 
                </Row>
                <Row>
                    <div className="entrevista-sub-text">
                        Entrevista originalmente publicada na Revista Rebeca - Revista Brasileira de Estudos de Cinema e Audiovisual, SOCINE, ISSN: 2316-9230  v.3 n. 1, 2014.
                    </div>
                    <div className="entrevista-sub-text">
                        <a>
                            https://rebeca.socine.org.br/1/article/view/124 
                        </a>
                    </div>
                    <div className="entrevista-sub-text">
                    Entrevista e fotos Susana Dobal 
                    </div>
                </Row>
                <Row className="entrevista-texto">
                    <p>
                        Melanie Einzig trabalha há mais de dez anos em New York fotografando eventos públicos e privados. Além do trabalho comercial que pode ser visto no seu site  [ na palavra SITE acrescentar link https://melanieeinzig.com/ ],ela desenvolve um trabalho independente fazendo fotos de rua que oferecem um comentário agudo sobre os novaiorquinos ou, de maneira mais genérica, sobre a cultura americana – ela também fotografa em outras cidades do país. Além de publicado em antologias como  Street Photography Now [ https://thamesandhudson.com/street-photography-now-9780500289075 ] o trabalho da fotógrafo tem sido amplamente exposto na Europa e nos Estados Unidos. A atenção que ela dá a pessoas e objetos que oferecem um testemunho de diferentes aspectos da vida norte-americana coloca suas fotografias na linhagem de Walker Evans e Robert Frank. 
                    </p>
                    <p>
                    Ela tem, no entanto, um estilo próprio, trabalhando com composições complexas cujo sentido se origina na combinação inesperada de pessoas diferentes e na interação com objetos e elementos do cenário em volta. Seus personagens são frequentemente tão solitários como os do pintor Edward Hopper, mas eles podem ser flagrados em meio à agitação urbana e, diferente do pintor, ela se interessa mais em trazer à tona singularidades da cultura americana. Em meio a tantas transformações por que passa a fotografia documental, Melanie Einzig recorre ao gesto aparentemente simples de fotografar na rua para construir uma narrativa singular sobre a vida urbana americana. Ao invés do heroísmo e da catástrofe associada a boa parte da produção cinematográfica hollywoodiana, seu olhar vacila entre ora uma atitude irônica, ora uma melancólica cumplicidade com o que vê a sua volta.
                    </p>
                    <p>
                    As fotografias dela que podem ser vistas nas fotografias exibidas aqui foram mostradas pela própria fotógrafa durante a entrevista; outras imagens podem ser acessadas pelos links propostos ao longo da entrevista. Além do texto, essa entrevista também traz um ensaio fotográfico com texto que desenvolve algumas ideias sobre o trabalho de Einzig. Fotografia e texto são parte um projeto maior de foto-entrevistas com pessoas que fazem diferença na sua abordagem da fotografia, sejam fotógrafos 
                    <ModalLink n={0}/>
                    ou pensadores. O ensaio paralelo desenvolve uma segunda narrativa que pode ser acessada pelos links ao longo da entrevista.
                    </p>
                    <div className="green-box">
                    Você situaria o seu trabalho na tradição do fotojornalismo  ou da fotografia de rua (street photography)?

                    </div>
                    <p>
                    Meu trabalho tem sido definido como street photography. Isso me parece aceitável 
                    <ModalLink n={1}/>
                    , mas eu não sei. Não é realmente fotojornalismo, embora em algum ponto eu tenha pensado que poderia fazer uma carreira nessa área. Acho que de alguma forma eu uso a linguagem do fotojornalismo, da fotografia de agências como a AP, direta, sem complicações, mas estou tentando comunicar alguma coisa mais complexa, peculiar, pessoal. Um tipo de observação que não se baseia no flagrante do momento mas certamente também busca alcançar o que se chama de objetividade no mundo da notícia.

                    </p>
                    <div className="green-box">
                    Você acha que fotografaria de outra maneira se não tivesse começado a sua carreira e trabalhado por tanto tempo em New York ? A cidade teve um papel importante em informar e educar os seus olhos?

                    </div>
                    <p>
                    Com certeza. Primeiro fiz uma viagem para a Índia onde a vida era tão diferente de tudo o que eu tinha vivido ao crescer nos subúrbios do oeste americano. Eu me senti totalmente surpreendida e estimulada e então percebi que a vida é assim, em todos os lugares, se a pessoa olha atentamente. Quando me mudei para New York eu me lembro de pensar que talvez eu jamais precisasse viajar novamente pois uma ida à bodega na esquina já era uma aventura dinâmica e completa. Ainda me sinto assim em relação a New York 
                    <ModalLink n={2}/>
                    . Correr de um lado para outro é diferente a cada dia, com todo um novo grupo de personagens e dramas se desenrolando à minha frente e eu bem no meio da cena. 
   
                    </p>
                    <div className="green-box">
                    O seu trabalho sempre tem uma atenção para as exceções – no seu site, um casal gay abre a série de fotografias de casamento; no tópico eventos, festas e conferências, uma senhora com flores radiantes e brancas abre a série; tem também crianças com sua presença singular em meio aos adultos em sessões diferentes do site. Você diria que procura deliberadamente por pessoas que podem dar um ponto de vista diferente sobre a vida, de maneira mais geral ?

                    </div>
                    <p>
                    Sim, eu procuro celebrar os menos celebrados e gusto de apresentar contra-narrativas para o que as pessoas esperam ver. Eu também apenas vejo dessa forma. Percebo coisas que algumas pessoas não percebem e acho que vejo diversão e beleza em coisas que passam despercebidas pelo radar mais convencional 
                    <ModalLink n={3}/>
                    . Eu gusto de colocar esse tipo de trabalho bem a frente das minhas fotos de eventos para poder atrair coisas que me interessam. Gosto de casamentos gays, aniversários de 90 anos, conferencistas e performers visionários ou que defendem uma política com a qual eu concordo. Ou às vezes eu debocho de quem eu não concordo muito. Eu tento trazer um pouco da minha visão pessoal para as minhas fotos de eventos. Mas em geral eu estou apenas me esforço para cobrir todo mundo e fazer com eles saiam bem na foto.

                    </p>
                    <div className="green-box">
                    O que você acha que pode ter originado essa busca pela diferença? 

                    </div>
                    <p>
                    Talvez eu nunca tenha em sentido normal. Eu sempre me senti meio à margem das coisas e um pouco estranha. É difícil de explicar mas embora eu me identifique como uma mulher, às vezes me sinto como um homem fingindo ser uma mulher. Eu também me identifico como uma judia  
                    <ModalLink n={4}/>
                    , mas às vezes me sinto mais próxima do budismo e do hinduísmo ou posso até mesmo ter uma forte afinidade com a Nossa Senhora. Eu sou uma fotógrafa, mas secretamente eu também quero ser uma poeta ou talvez eu pudesse ser uma psicóloga se eu conseguisse ouvir por mais do que cinco segundos. Meus pais costumavam me dizer que eu me isolava dos outros, mas nunca achei que fosse verdade. Eu nunca senti que eu realmente cabia nas situações. Não que eu me sentisse solitária, sempre tive amigos e andei com muitos grupos diferentes mas de alguma forma eu nunca senti que eu realmente cabia nas categorias habituais de pertencimento. E sempre me senti a um passo de distância das coisas 
                    <ModalLink n={5}/>
                    . Como uma obsevadora nata. Talvez esteja aqui a origem do que você chama de “exceções”: pessoas ou situações que fazem parte da imagem mas não são representativas de um princípio geral ou de um entendimento sobre a pessoa ou a situação. 
                    <ModalLink n={6}/>
                    .

                    </p>
                    <div className="green-box">
                    Suas fotos fazem um comentário perspicaz sobre a cultura americana e sobre os novaiorquinos. Como você faz quando viaja pra outros países como a Turquia? Você se prepara antes? Você acha que olha para Istambul ou para a cidade do México como os mesmos olhos que vagueiam em New York?

                    </div>
                    <p>
                    Geralmente eu não me preparo muito antes de viajar. Eu leio guias como o Lonely Planet no avião, mesmo que eu tivesse a intenção de ler antes. Muitas vezes eu contei com amigos ou a família para me mostrar um lugar ou me dizer onde ir. É um pouco infantil da minha parte. Eu moro em New York há muito tempo e nos raros momentos que eu viajo eu tendo a explorar a pé e ver o que posso ver. Depois de uma semana ou mais começo a entender algumas coisas mas de forma alguma tenho os mesmos insights  sobre a cultura local que tenho nos Estados Unidos, então minhas fotos provavelmente não são tão pungentes ou reveladoras em outros países. Mas acho que fiz fotos boas aqui e ali 
                    <ModalLink n={7}/>
                    .

                    </p>
                    <div className="green-box">
                    Você também trabalhou com vídeo. O que te fez escolher também a imagem em movimento [*LINK: https://vimeo.com/meinzig]? Há uma diferença entre o seu trabalho com vídeo e com a fotografia?

                    </div>
                    <p>
                    No passado eu fiz alguns vídeos e filmes. Eram principalmente experimentações 
                    <ModalLink n={8}/>
                    . Eu gostaria de fazer mais vídeos e tenho algumas ideias de juntar clips que colecionei. Mas acho o trabalho de edição meio frustrante e entediante, é uma habilidade que não desenvolvi. Às vezes eu vejo alguma coisa e penso: isso é um vídeo e não uma foto. Tenho usado o vídeo como uma foto em movimento. É só um tempo um pouco maior do que 1/250 de segundo 
                    <ModalLink n={9}/>
                    .

                    </p>
                    <div className="green-box">
                    Você ainda desenha?

                    </div>
                    <p>
                    Sim, de vez em quando. Eu passei uns dois anos sem fotografar muito e fiquei meio desinteressada pela fotografia. Alguém me disse que é melhor experimentar outra forma de criação quando a pessoa se sente assim. Eu comecei a cozinhar e depois a desenhar. 
                    <ModalLink n={10}/>
                    Coisas surpreendentes começaram a acontecer com os desenhos. Eu senti um tipo de excitação infantil. Eu costumava desenhar assim quando era jovem e chamava isso de “fotos rabiscadas”. Agora que tenho mais idade elas estão vindo mais estruturadas. Cada uma me surpreende. E consigo desligar 
                    <ModalLink n={11}/>
                    , por um momento, minha mente pensante. 

                    </p>
                    <div className="green-box">
                    Você ainda tem na sua parede uma foto do mendigo atravessando a rua com a imagem dos soldados 
                    <ModalLink n={12}/>
                    num anúncio à frente dele?

                    </div>
                    <p>
                    Há uns anos atrás eu pensei que manteria aquela foto na parede até que os Estados Unidos saíssem da guerra. Mas isso não aconteceu 
                    <ModalLink n={13}/>
                    .

                    </p>
                    <div className="green-box">
                    Qual a imagem que você tem agora na sua parede?

                    </div>
                    <p>
                    Eu agora tenho uma outra imagem. Ela se chama Deleite Turco 1962/2008. É um conjunto de fotos antigas que achei em uma caixa numa feira em Instambul. Eu estava cavando na caixa e essa mulher ficava aparecendo. Eram fotos dela sempre parecendo feliz 
                    <ModalLink n={14}/>
                    e muito provavelmente de férias. A estrutura das fotos era bem consistentes, foram tiradas por alguém com um bom olho. Imagino que fosse alguém apaixonado. Ela parece tão feliz e as fotos também são felizes, como se o fotógrafo estivesse se deleitando em fazer aquelas fotos dela. Adoro olhar essas imagens e quero estar naquela situação, com um admirador me olhando e excitado com a vida 
                    <ModalLink n={15}/>
                    . Mas eu ainda espero que as guerras acabem logo. Não posso acreditar que evoluídos como somos enquanto espécie, ainda somos capazes de bombardear uns aos outros, atirar ou atacar com um facão. 

                    </p>
                    <div className="green-box">
                    Há muito humor nas suas imagens. De onde ele vem? 

                    </div>
                    <p>
                    Provavelmente vem do fato de eu ter uma tendência depressiva 
                    <ModalLink n={16}/>
                    . O humor é uma maneira como a vida me levanta e me tira do que eu chamo de o meu “nível baixo de sofrimento”. Recentemente ouvi Tony Kusher dizer que ele achava que o humor tem uma qualidade agressiva subentendida. Acho isso muito interessante. Meu analista diz que a agressividade e a criatividade estão muito relacionados. Então tudo dá nisso: depressiva...agressiva...criativa/engraçada. Talvez seja uma forma de transformar impulsos agressivos e raivosos em alguma coisas divertida ou pelo menos em algo para me alegrar 
                    <ModalLink n={17}/>
                    .

                    </p>
                    <div className="green-box">
                    O que você acha do Instagram? Você posta o seu trabalho pessoal nele ou em alguma rede social?

                    </div>
                    <p>
                    Eu fico secretamente irritada com o Instagram. Agora que tornei isso público, melhor investigar isso e explicar. Essa era digital é tão sobrecarregada de fotos, é como se meus olhos quisessem parar e ver menos. Eu não gosto tanto dos filtros, eles fazem as fotos de todos parecerem ótimas e falsas e não sinto tanta intensidade quando vejo essas imagens. Meu amigo me disse que eu deveria entrar, que todos estão fazendo isso e que está ajudando na carreira deles. Para mim parece uma empresa que está juntando um monte de fotos e vai de alguma maneira achar um jeito de usá-las em benefício próprio como um arquivo ou algo assim. Posso estar errada, mas sou bastante cética. Não julgo as pessoas por usar ou por gostar do Instagram. Só que não é pra mim. Eu também não preciso de mais uma forma de gastar o meu tempo precioso. Já gasto tempo suficiente no Facebook para me atualizar com a família, os amigos e as últimas notícias. 
      
                    </p>
                    <div className="green-box">
                    Qual é a necessidade hoje de haver fotografia ? Por que uma pessoa deveria se dedicar à fotografia atualmente?

                    </div>
                    <p>
                    Eu acredito 
                    <ModalLink n={18}/>
                    que a fotografia é vital para comunicar o que está acontecendo no mundo e nos ajudar a ter opinião e agir. Eu jamais gostaria que desaparecesse o uso de fotografias com a notícia e acho que os jornais deveriam investir mais em fotógrafos e fotojornalismo ao invés de menos, como estão fazendo agora. Tenho ouvido que estão pedindo para quem escreve no jornal fazer suas próprias fotos. Mas fotógrafos são pensadores visuais, são termômetros. A perspectiva deles pode ajudar muito a palavra escrita, pode acessar e transmitir o que muitas vezes as palavras não alcançam. Para aqueles de nós que podem ver, precisamos disso. O registro histórico do que significa ser humano, mesmo não sendo uma notícia, é facilmente transmitido por fotografias. Esses dois pontos são muito importantes para mim. Sobre a fotografia enquanto criação ou documento estético – bom, há muita produção desinteressante por aí. Mas realmente, a minha vida seria bastante empobrecida se nunca tivesse visto Kertész, Arbus, Singh, Brassaï, Leiter. A lista não tem fim 
                    <ModalLink n={19}/>
                    . Esses fotógrafos alimentaram a minha alma e por favor não me pergunte o que isso quer dizer.

                    </p>
                </Row>
                <Row className="entrevistas-carousel-row">
                    <CarouselDefault images={images()} heigth={"700px"}/>
                </Row>
                <Row>
                    {
                        array15.map((v, i)=> (
                            <EntrevistaImageModal 
                                src={getImagePath(i+1)}
                                isOpen={modalControl[i]}
                                handleClose={handleClose(i)}
                                key={"modal-entrevista-"+i}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}