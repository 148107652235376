import { Carousel, Container } from "react-bootstrap";
import Header from "../../../components/Header";

import "../../../css/screens/fotografia/Mundo.css"
import Footer from "../../../components/Footer";

import Slider from "react-slick";

export default function Mundo(){
    let images = [
        {
            src: "assets/fotografia/mundo/01.jpg",
            legenda: "Sitiado, Yasser Arafat dorme no chão e come conservas em lata"
        },
        {
            src: "assets/fotografia/mundo/02.jpg",
            legenda: "Imigrantes ilegais são resgatados após naufrágio na costa da Argélia"
        },
        {
            src: "assets/fotografia/mundo/03.jpg",
            legenda: "Presos fazem reféns e queimam colchões durante rebelião em Bauru"
        },
        {
            src: "assets/fotografia/mundo/04.jpg",
            legenda: "Ex-deputado faz cadastro e promete R$ 100 por voto (10) [10/13]"
        },
        {
            src: "assets/fotografia/mundo/05.jpg",
            legenda: "Carro-bomba explode em Medellín; suposto autor do atentado é morto "
        },
        {
            src: "assets/fotografia/mundo/06.jpg",
            legenda: "Índios morrem em emboscada em Pernambuco"
        },
        {
            src: "assets/fotografia/mundo/07.jpg",
            legenda: "Explosões são ouvidas em Bagdá e combates se intensificam"
        },
        {
            src: "assets/fotografia/mundo/08.jpg",
            legenda: "Morre em São Paulo o cineasta Rogério Sganzerla"
        },
        {
            src: "assets/fotografia/mundo/09.jpg",
            legenda: "Policiais fazem segurança nas favelas da Rocinha e do Vidigal"
        },
        {
            src: "assets/fotografia/mundo/10.jpg",
            legenda: "Aeroporto opera normalmente"
        },

    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <div style={{position: "relative"}}>
        <Header />
        <div className="mundo-container">
            <div className="mundo-text-namidia">
                na mídia
            </div>
            <div className="mundo-text-mundo">
                Mundo
            </div>
            <div className="mundo-text-text1">
                <p>
                Em Brasília há sempre uma sensação de que a vida está ocorrendo em algum outro lugar, mas ao mesmo tempo o fato de estarmos longe pode ser uma condição para estarmos bem perto.
                </p>
                <p>
                Esse território isento de tradição e sem saída para o mar tem duas faces, cada qual enigmática a seu modo: um lado mostra ângulos retos, luz ofuscante, espaço vazio e relevo cambiante de nuvens; o outro lado, porém, menos explícito, esconde corredores de pilotis e árvores, e é secreto como um mangue que vemos geralmente de fora, e raramente de dentro. Os transeuntes, como caranguejos sorrateiros e camuflados, fazem eventuais aparições nesse labirinto mais recôndito. É lá que o burburinho do mundo ecoa, veemente. 
                </p>
                <p>
                Parte do ensaio foi publicada no livro Abstrata Brasília Concreta de Wagner Hermuche (Brasília, Medialecom, 2003).  
                </p>
                <p className="mundo-text-text3">
                    Mundo<br></br>
                    Exposição individual no Conjunto Cultural da CEF. Brasília, 2004.<br></br>
                    Cópias cromogênicas<br></br>
                    25 fotografias 20 x 30 cm e 5 fotografias 40 x 60 cm
                </p>
            </div>

                <div className="carousel-wrapper">
                    <Slider {...settings}>
                        {images.map((image)=>(
                            <div  className="slide">
                                <img src={image.src} alt="Imagem 1"  style={{ display: "block", margin: "0 auto" }} />
                                <div className="legend">
                                    <b>
                                        {image.legenda}
                                    </b>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

        </div>
        <Footer />
    </div>;
}