
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function EntrevistaImageModal(props:{src:string, isOpen:boolean, handleClose:any}) {
  return (
    <>
      <Modal 
        show={props.isOpen} 
        onHide={props.handleClose} 
        centered
        size="lg" 
        style={
          {backgroundColor: "rgba(0, 0, 0, 0) !important"}
        }
        >
        <Modal.Body 
        style={{
          maxWidth: "80vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
            <img 
                src={props.src} 
                alt="Imagem 1"  
                style={{objectFit:"scale-down", height: "800px"}} 
            />
        </Modal.Body>
      </Modal>
      <style>
        {".modal-content { background-color: rgba(0, 0, 0, 0) !important; border: none !important;}"}
      </style>
    </>
  );
}
