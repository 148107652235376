import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";
import { CarouselLink } from "../../../components/MainCarousel";


export default function Ecos(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/06_Ecos Parisienses_2013/Dobal_19_P1040217_mn.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                        Ecos parisienses
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Os ecos de Paris referem-se menos aos rastros que toda cidade pode deixar em cada transeunte do que à redescoberta de vestígios. Naquela cidade, há uma sensação difusa de reconhecimento de algo já vivido anteriormente, algo ao mesmo tempo estranho e familiar. A estranheza se explica quando, eventualmente, a lembrança se torna menos difusa, revelando que a vivência anterior da cidade pode ter sido apenas pelos olhos de outros – de um pintor, um escritor, um poeta, um fotógrafo, um cineasta, um amigo. O redemoinho de gente, personagens, cenas, cidades e tempos diversos, tempo confuso da memória de coisas vividas, impregnada pela memória de coisas lidas, ouvidas e vistas, volteia em torno de um âmago onde o antes e o depois se confundem.
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        Narrativa fotográfica com 56 imagens publicada na Revista Studium (UNICAMP), n. 34. Julho de 2013
                        </div>
                        <div style={{fontSize:"1.2em"}}>
                            <CarouselLink 
                                src={"https://www.studium.iar.unicamp.br/34/3/"}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper" onClick={()=>{window.location.href="https://www.studium.iar.unicamp.br/34/3/"}}>
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}