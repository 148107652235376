
import { Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TopBarImage from "../../components/TopBarImage";
import "../../css/screens/bio/Cv.css";

export default function Cv(){

    return <>
        <Header />
        <TopBarImage />
        <div className="page-background">
            <Container className="bio-container">
                <Row className="bio-titulo">
                <b>
                    formação
                    </b> 
                </Row>
                <Row className="bio-text">
                    - Pós-doc na Aix-Marseille Université (2014) e na Université Paris 8 (2009)
                    <br></br>
                    - Doutorado em História da Arte (2002) - City University of New York (CUNY/Graduate Center)
                    <br></br>
                    - Mestrado em Fotografia (1994) - New York Univesity e International Center of photography
                    <br></br>
                    - Especialização em Teoria da Literatura (1992) - Universidade de Brasília (UnB)
                    <br></br>
                    - Graduação em Jornalismo (1988) e em Língua e Literatura Brasileira (1989) - UnB
                    <br></br>
                </Row>

                <Row className="bio-titulo">
                <b>
                exposições individuais
                    </b> 
                </Row>
                <Row className="bio-text">
                
                    - 2007 	Além-mar. Espaço Cultural Renato Russo. Brasília. 
                    <br></br>
                    - 2004 	Mundo. Anexo cultural da CEF, Brasília.
                    <br></br>
                    - 2001 	Love Stories II (foto-instalação). Galeria da CAL (Casa da América Latina), Brasília.
                    <br></br>
                    - 1999	Love Stories I  (instalação). Boate Millenium. Brasília. 
                    <br></br>
                    - 1998	Rastros (fotografias). Projeto Prima Obra - FUNARTE. Brasília.
                    <br></br>
                    - 1990 	Still e exposição de fotos dos videos "Brasiconoscópio" de Mauro Giuntini e "Rodoviária" de César Mendes no Teatro Nacional de Brasília.
                    <br></br>
                    - 1986 	Still e exposição de fotos do vídeo "Estudantes do Brasil" de George Duarte e Cristina Quadra. Auditório Dois Candangos /Universidade de Brasília
                    <br></br>
                </Row>

                <Row className="bio-titulo">
                <b>
                    exposições coletivas
                    </b> 
                </Row>
                <Row className="bio-text">
                    
                    - 2019 	Prêmio Nacional de Fotografia Pierre Verger. Menção Honrosa na categoria Inovação e Experimentação. Fundação Pierre Verger, Palacete das Artes, Salvador.
                    <br></br>
                    - 2016 	Salão Mestre d'Armas, 2016. Museu Histórico e Artístico de Planaltina. Planaltina. 
                    <br></br>
                    - 2015	Ladrões de Alma: 25 anos. Brasília. Galeria Casa da Luz Vermelha.  
                    <br></br>
                    - 2014	Repères, 2014. Galerie Christian Depardieu, Nice, França. 
                    <br></br>
                    - 2013	Foto Rio 2013.  Centro Cultural da Justiça Federal. Rio de Janeiro, RJ. 
                    <br></br>
                    - 2012    MAB - Diálogos da Resistência. Museu Nacional do Conjunto Cultural da República, Brasília.  de 9.2.2012 a 18.3.2012
                    <br></br>
                    - 2011    Retratos falantes. Foto Rio 2011. Centro de Arte Hélio Oiticica. Rio de Janeiro, RJ.
                    <br></br>
                    - 2010    Diversidade e Afinidades: Universo x Reverso. Brasília, ECCO.
                    <br></br>
                    - 2009    Deux artistes Brésiliens à Nice - Luciano Figueiredo e Susana Dobal. Galerie Christian Depardieu,  Nice (França).
                    <br></br>
                    - 2008   	No sabe/no contesta: prácticas fotográficas lationamericanas. Buenos Aires, Galeria Arte x Arte.
                    <br></br>
                    - 2006    Melhor de três: Três Tempos - Evandro Salles, Elyezer Szturm, Susana Dobal. Casa da Cultura da América Latina. 
                    <br></br>
                    - 2006    Reizado. Livraria Esquina da Palavra. Brasília. (março de 2006)
                    <br></br>
                    - 2005    Cotidiano. Foto Rio 2005. Centro Cultural Justiça Federal. Rio de Janeiro,RJ. 
                    <br></br>
                    - 2005  	Ladrões de Alma: Em contato. FotoArte 2005. Foyer da Sala Vila Lobos, Teatro Nacional,Brasília.
                    <br></br>
                    - 2005    Quase Coisas. Grupo Ladrões de Alma. Conjunto Cultural da Caixa no Rio de Janeiro. 
                    <br></br>
                    - 2005    Situações Brasília. Caixa Econômica de Brasília e no Centro Cultural Brasil Espanha em março de 2005.
                    <br></br>
                    - 2003    Desdocumentos - Grupo Ladrões de  Alma. FotoArte Brasília 2003. Casa Park, Brasília.
                    <br></br>
                    - 2002    Arte Contemporânea Brasília 2002. Galeria Arte Futura. Brasília.
                    <br></br>
                    - 2002    Quase Coisas - Grupo Ladrões de Alma. CEF, Brasília  e São Paulo (2003).
                    <br></br>
                    - 2000    Ladrões de Alma. Espaço Cultural Cozinha das Almas. Brasília.
                    <br></br>
                    - 1998    Premios Villa de Madrid. Centro Cultural del Conde Duque. Madri, Espanha.
                    <br></br>
                    - 1998    Abrangente - Grupo Ladrões de Alma. Galeria Athos Bulcão. Teatro Nacional de Brasília.
                    <br></br>
                    - 1997    Vida Selvagem (foto- instalação). Projeto Interações. Galeria Athos Bulcão. Teatro Nacional de Brasília
                    <br></br>
                    - 1997    Ali no Bar e os Dez Ladrões. Café Martinica, Brasília.
                    <br></br>
                    - 1997    Ladrões de Alma: fotografias. Café La Película, Brasília.
                    <br></br>
                    - 1994    Under the awning. 80 Washington Square East Galleries, NY. 
                    <br></br>
                    - 1994    Self-identity and Portraiture. Rosenberg Gallery, NY.
                    <br></br>
                    - 1993    Art Around the Park. Thompkins Square, NY. 
                    <br></br>
                    - 1993   	Cem ovos azuis" ( instalação ). Violet Cafe, NY.
                    <br></br>
                    - 1993   	Ladrões de Alma. FUNARTE, Rio  de Janeiro.
                    <br></br>
                    - 1991    Brasília: Perdidos no espaço. Grupo Ladrões de Alma. Mezanino do Teatro Nacional de Brasília.
                    <br></br>
                    - 1990    	Os ladrões de alma na noite do vampiro Torquato Neto. Biblioteca Pública de Brasília.
                    <br></br>
                    - 1990    100 imagens . Grupo Ladrões de Alma. Centro de Cultura Hispânica. Brasília.                  
                    <br></br>
                    - 1990    Ensaios Particulares. Grupo Ladrões de Alma. Anexo Cultural da Caixa Econômica (CEF). Brasília.
                    <br></br>
                    - 1988 e 89  Exposições e lançamento de duas coleções de postais em Brasília com o grupo      Ladrões de Alma.
                </Row>

                <Row className="bio-titulo">
                <b>
                ensaios fotográficos publicados online
                    </b> 
                </Row>
                <Row className="bio-text">
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2023 A Short Glossary to Decipher a Strange Phenomenon.  Afterimage: The Journal of Media Arts and Cultural Criticism. University of California Press. March 2023. eISSN: 2578-8531.   https://online.ucpress.edu/afterimage/issue/50/1
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2022 Paisagens invistas. Ensaio fotográfico acompanhando entrevista com Karina Dias. In Dobal, Susana e CASTANHEIRA, Rafael. A Paisagem como Narrativa. Brasília: FAC Livros, 2022. https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/ 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2022 O que o vento ainda não levou. Cadernos do Ceam. Ano XXII, n. 37, jan 2022. Arte e Inovação em Tempos de Pandemia 2  - Artes Visuais. Rodolfo Ward (ed.). Brasília: Centro de Estudos Avançados Multidisciplinares/ UnB. https://www.academia.edu/72103054/Cadernos_do_Ceam_37_Arte_e_Inova%C3%A7%C3%A3o_em_Tempos_de_Pandemia_2_Artes_Visuais_
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2021 Ebulição. In MORAES, Denise e MENDES, Jessica (eds.). Faces da pandemia: memórias afetivas.  https://facespandemia.wixsite.com/facesdapandemia/des-encontros 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2020 Clarice Lispector em Brasília. Revista Sotaques Brasil Portugal, n. 28. Set out 2020. Porto. https://issuu.com/sotaques/docs/rsbso/58 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2018 Notícias do verão. Revista Esferas n. 13  julho-dezembro 2018. P. 133-146. https://portalrevistas.ucb.br/index.php/esf/article/view/10339 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2017 Imagem e Tempo em Movimento. Ensaio fotográfico acompanhando entrevista com Philippe Dubois. Revista Discursos Fotográfico.  Universidade Estadual de Londrina/UEL, v. 13, n. 22, p. 258 a 284, jan-jul 2017.  http://www.uel.br/revistas/uel/index.php/discursosfotograficos/article/view/30301/pdf 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2017 Tiago Santana entre fotografías, fragmentos y libros. Ensaio fotográfico acompanhando entrevista com Tiago Santana. Revista de Estudos Brasileños, Universidad de Salamanca e USP, v. 4, n.8, p. 177-189, jul-dez 2017.   https://revistas.usal.es/cuatro/index.php/2386-4540/article/view/reb201748177189/19802  
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2014 Melanie Einzig: Ironia e melancolia nas ruas de New York. Ensaio fotográfico acompanhando entrevista com Melanie Einzig. Revista Rebeca - Revista Brasileira de Estudos de Cinema e Audiovisual, SOCINE, ISSN: 2316-9230  v.3 n. 1, 2014. https://rebeca.socine.org.br/1/article/view/124 
                    </Row>
                    <Row style={{textIndent: "-3em", marginLeft: "2em"}}>
                    - 2012 Ecos Parisienses. Revista Studium (UNICAMP), n. 34, julho de 2013. https://www.studium.iar.unicamp.br/34/3/
                    </Row>
                </Row>

                <Row className="bio-titulo">
                <b>
                livro publicado
                    </b> 
                </Row>
                <Row className="bio-text">
                    Peter Greenway and the Baroque: writing puzzles with images. Saarbrücken, Germany: LAP Lambert Academic Publishing, 2010 p.240.
                    https://www.amazon.com/Peter-Greenaway-Baroque-Writing-puzzles/dp/3838327993 
                </Row>

                <Row className="bio-titulo">
                    <b>
                        livros organizados
                    </b> 
                </Row>
                <Row className="bio-text">
                    DOBAL, Susana; CASTANHEIRA, Rafael (eds.). A Paisagem como narrativa: quando a imagem inventa o espaço. Brasília: Editora FAC Livros, 2022. https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/
                    <br></br>
                    DOBAL, Susana M.; GONÇALVES, Osmar (eds.). Fotografia Contemporânea: fronteiras e transgressões. Brasília: Casa das Musas, 2013. https://issuu.com/paisagemunb/docs/fotografia_contempor_nea_-_fronteiras_e_transgress 
                </Row>

                <Row className="bio-titulo">
                    <b>
                        artigos publicados em periódicos
                    </b> 
                </Row>
                <Row className="bio-text">
                    <br></br>
                    - Quando o céu desaba e as imagens falam: experiências visuais em uma exposição índia. Revista Esferas, (UCB, UnB, UFG, UFMS, UFMT), v.01 (16), 2019. p.122-132.  https://portalrevistas.ucb.br/index.php/esf/article/view/11087 
                    <br></br>
                    - Manoel de Barros: entre fotografias do idioma inconversável das pedras. Revista Studium (UNICAMP). Campinas, SP, n. 40, 2018. p. 188-210. https://econtents.bc.unicamp.br/inpec/index.php/studium/article/view/12612 
                    <br></br>
                    - Autorretratos: transcendendo a subjetividade. Revista NUPEM, Universidade Estadual do Paraná , v.8, n. 15, 2016. p.209 - 219. https://periodicos.unespar.edu.br/index.php/nupem/article/view/5494 
                    <br></br>
                    - Quando sujeitos inconstantes fotografam espaços maleáveis. Discursos Fotográficos, (UEL - Universidade Estadual de Londrina), v.13 (22), p.89 - 106 , 2017. https://ojs.uel.br/revistas/uel/index.php/discursosfotograficos/article/view/30298 
                    <br></br>
                    - Sobre Susan Sontag: a fotografia como pensamento engajado. Discursos Fotográficos, (UEL - Universidade Estadual de Londrina), v. 13, n. 23, p. 37-59, 2017. DOI: 10.5433/1984-7939.2017v13n23p37 https://ojs.uel.br/revistas/uel/index.php/discursosfotograficos/article/view/26367 
                    <br></br>
                    - Hamish Fulton: muitas caminhadas e uma obra. Revista Studium (UNICAMP), n.36, julho 2014. p.47 - 69.  https://www.studium.iar.unicamp.br/36/3/#_ftn1
                    <br></br>
                    - Kieslowski, Jarman e Klein: Variações em torno do Azul. Revista Laika (USP), v.2, 2013. file:///Users/susana/Downloads/137188-Texto%20do%20artigo-264755-1-10-20170818.pdf 
                    <br></br>
                    - Sete Sintomas de Transformação da Fotografia Documental. Revista ÍCONE (UFPE - Universidade Federal de Pernambuco), v.14, 2012. https://periodicos.ufpe.br/revistas/icone/article/view/230628 
                    <br></br>
                    - Tempo fotográfico e tempo cinematográfico: reciprocidades. REVISTA Laika, (USP) v.1, n.2, 2012. https://www.revistas.usp.br/revistalaika/article/view/137176 
                    <br></br>
                    - Paranoia: um delírio entre a poesia, a fotografia e o cinema. Revista Studium, (UNICAMP), n. 32, inverno 2011.  http://www.studium.iar.unicamp.br/32/5.html
                    <br></br>
                    - Três imagens para relatar esculturas impalpáveis. Concinnitas (Rio de Janeiro, UERJ), v. 1 Ano 10, 2009, p. 42-53. https://www.e-publicacoes.uerj.br/index.php/concinnitas/article/view/55522
                    <br></br>
                    - Pele, cinema e fotografia. Comunicação e Espaço Público (UnB). , v.Ano XI, p.22 - , 2008. (impresso). https://repositorio.unb.br/bitstream/10482/12172/1/ARTIGO_PeleCinemaFotografia.pdf 
                    <br></br>
                    - Etienne-Jules Marey : nem mais fotografia, nem ainda cinema. Lugar Comum. UFRJ, v.x, p.161 - 176, 2001. (impresso)
                    <br></br>
                    - Ciência e exotismo: os índios na fotografia brasileira do século XIX. Cadernos de Antropologia e Imagem (UERJ). , v.1, p.67 - 85, 2001. (impresso)
                    <br></br>
                    - Os grafites de Brassaï: documento de um imaginário histórico. Lugar Comum, UFRJ, v.1, p.221 - 238, 1998. (impresso)
                </Row>

                <Row className="bio-titulo">
                    <b>
                        capítulos de livros publicados
                    </b> 
                </Row>
                <Row className="bio-text">
                    <br></br>
                    - Da Land Art ao acampamento Terra Livre: paisagens em trânsito. In A Paisagem como narrativa: quando a imagem inventa o espaço. DOBAL, Susana e  CASTANHEIRA, Rafael (orgs.). Brasília: Editora FAC Livros, 2022. p. 109-132. https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/ 
                    <br></br>
                    - Marcos Bonisson: quase-fotografias no zigue-zague do percebido e do perceber. In Magalhães, Angela; Peregino, Nadja; Carvalho, Victa de e Fatorelli, Antonio (orgs.). Coleção Midiateca: Escritos sobre Fotografia contemporânea: 1979-2004. Vol. 4. Rio de Janeiro, RJ: Lp press, 2022. p. 155-170.
                    <br></br>
                    - David Claerbout: la narrativité entre cinéma et photographie' In: ARNAUD, Jean (org.). Espaces d'Interférences Narratives: art et récit au XXIe siècle. Toulouse: Presses universitaires du Midi, 2018, p. 151-160. 
                    <br></br>
                    - Espaços vividos em narrativas fotográficas: o sertão e New York. In: BERARDO, Rosa e JESUS, Samuel de (orgs.).  Cultura, Identidade Cultural e Representacão da Alteridade.9 ed.Goiânia: UFC/FAC; FUNAPE, 2016, p. 61-84. 
                    <br></br>
                    - La photographie contemporaine : oscillations entre document et fiction. In: ARNAUD, Jean e GOOSSE, Bruno (orgs.).  Document, fiction et droit en art contemporain. Marseille: Presse Universitaire de Provence e Académie Royale des Beaux Arts de Bruxelles, 2015.  
                    <br></br>
                    - O desenredo da palavra no cinema. In: ARAÚJO, Denize Correa e BARBOSA, Marialva Carlos (orgs.). Imagíbrida: comunicação, imagem e hibridação. Porto Alegre: Plus, 2008. p. 185-206.
                    <br></br>
                    - Ficções e verdades no cinema, na fotografia e no cangaço. In: MONTORO, Tânia e CALDAS, Ricardo. De Olho na Imagem. Brasília: Fundação Astrojildo Pereira, Editorial Abaré, 2006, p. 123-138. 
                    <br></br>
                    - O Livro de Cabeceira. In: LOPES, Denilson. Cinema nos Anos 90. Chapecó: Argos, 2005, p. 139-152. 
                    <br></br>
                    - O zoológico barroco de Peter Greenaway. In: MACIEL, Maria Esther. O cinema enciclopédico de Peter Greenaway. São Paulo: Unimarco, 2004, p. 91-107. 
                    <br></br>
                    - Robert Bresson: O cinema em um atormentado silêncio. In: Estudos Socine de Cinema Ano III - 2001. Porto Alegre: Sulina, 2003, v.III, p. 275-281.
                </Row>

                <Row className="bio-titulo" style={{paddingBottom: "70px"}}>
                    <b>
                        Para a versão mais completa de publicações, ver o &nbsp;
                        <a className="link-default" href="http://lattes.cnpq.br/5063008053842213">
                        Currículo Lattes
                        </a>
                    </b> 
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}