
import { Container } from "react-bootstrap"
// import "../css/components/MainCarousel.css"

export function CarouselLink(props:any){

    let handleClick = () => {
        window.location.href = props.src
    }

    return <a onClick={handleClick} className="link-default">
        {props.text ? props.text : props.src}
    </a>
}

export default function MainCarousel({images}:any){
    

    return <div className="carouselContainer">
        <img
            className="imageCard imageCard-active"
            src={images[0].src}
        />
        <img
            className="imageCard imageCard-left"
            src={images[1].src}
        />
        <img
            className="imageCard imageCard-right"
            src={images[2].src}
        />
    </div>
}