
import { Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "../../css/screens/texto/Artigos.css"
import TopBarImage2 from "../../components/TopBarImage2";
import CarouselDefault from "../../components/CarouselDefault";

function Link(props:any){

    let handleClick = () => {
        window.location.href = props.src
    }

    return <a onClick={handleClick} className="link-default">
        {props.src}
    </a>
}

export default function Artigos(){
    let texts = [
        {
            main: "DOBAL, Susana. Marcos Bonisson: quase-fotografias no zigue-zague do percebido e do perceber. In Magalhães, Angela; Peregino, Nadja; Carvalho, Victa de e Fatorelli, Antonio (eds.). Coleção Midiateca: Escritos sobre Fotografia contemporânea: 1979-2004. Vol. 4. Rio de Janeiro, RJ: {Lp} press, 2022. p. 155-170."
        },
        {
            main: "DOBAL, Susana. Da Land Art ao acampamento Terra Livre: paisagens em trânsito. In A Paisagem como narrativa: quando a imagem inventa o espaço. DOBAL, Susana e  CASTANHEIRA, Rafael (orgs.). Brasília: Editora FAC Livros, 2022. p. 109-132.",
            link: "https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/"
        },
        {
            main: "DOBAL, Susana. Da Land Art ao acampamento Terra Livre: paisagens em trânsito. In A Paisagem como narrativa: quando a imagem inventa o espaço. DOBAL, Susana e  CASTANHEIRA, Rafael (orgs.). Brasília: Editora FAC Livros, 2022. p. 109-132. ",
            link: "https://portalrevistas.ucb.br/index.php/esf/article/view/11087"
        },
        {
            main: "DOBAL, Susana. Manoel de Barros: entre fotografias do idioma inconversável das pedras. Revista Studium (UNICAMP). Studium, Campinas, SP, n. 40, 2018. p. 188-210.",
            link: "https://econtents.bc.unicamp.br/inpec/index.php/studium/article/view/12612 "
        },
        {
            main: "DOBAL, Susana. Sobre Susan Sontag: a fotografia como pensamento engajado Discursos Fotograficos, [S. l.], v. 13, n. 23, p. 37-59, 2017. DOI: 10.5433/1984-7939.2017v13n23p37",
            link: "https://ojs.uel.br/revistas/uel/index.php/discursosfotograficos/article/view/26367"
        },
        {
            main: "DOBAL, Susana. Tempo fotográfico e tempo cinematográfico: reciprocidades. Revista Laika, USP, v. 1 n.2, 2012. p. 1-17.",
            link: "https://www.revistas.usp.br/revistalaika/article/view/137176"
        },
        {
            main: "DOBAL, Susana. Hamish Fulton: muitas caminhadas e uma obra. Revista Studium, UNICAMP, n. 36. Julho, 2014.",
            link: "https://www.studium.iar.unicamp.br/36/3/#_ftn1"
        },
        {
            main: "DOBAL, Susana. Paranoia: um delírio entre a poesia, a fotografia e o cinema. Revista Studium, UNICAMP, n. 32, inverno 2011.",
            link: "http://www.studium.iar.unicamp.br/32/5.html"
        },
        {
            main: "DOBAL, Susana. Três imagens para relatar esculturas impalpáveis. Concinnitas (Rio de Janeiro, UERJ), v. 1 Ano 10, p. 42-53, 2009.",
            link: "https://www.e-publicacoes.uerj.br/index.php/concinnitas/article/view/55522"
        },
        {
            main: "(texto literário) DOBAL, Susana. Carta Proibida a alguém no passado ou Atualizando René Magritte. Revista Letra Magna. Dossiê Arte Política Design Ativista. Vol. 19 n. 32, 2023. São Paulo, Instituto Federal de Educação, Ciência e Tecnologia. ",
            link: "https://ojs.ifsp.edu.br/index.php/magna/article/view/2214 "
        },
    ]

    let images = [
        {
            src: "assets/texto/artigos/03_08_2021_Marcos_Bonisson-01405_mn.jpg",
            legenda: (<>
                {texts[0].main}
                {texts[0].link && <>
                    <br></br>
                    <Link 
                        src={"https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/"}
                    />
                </>}
            </>)
        },
        {
            src: "assets/texto/artigos/Diego_Bresani_Bsb_Esplanada.jpg",
            legenda: (<>
                {texts[1].main}
                {texts[1].link && <>
                    <br></br>
                    <Link 
                        src={"https://faclivros.wordpress.com/2022/08/03/lancamento-fac-livros-a-paisagem-como-narrativa-quando-a-imagem-inventa-o-espaco/"}
                    />
                </>}
                <br></br>
                <b> Foto de Diego Bresani</b>
            </>)
        },
        {
            src: "assets/texto/artigos/A Queda_do_Ceu_IMG-1309_flechas_mn.jpg",
            legenda: (<>
                DOBAL, Susana. Quando o céu desaba e as imagens falam: experiências visuais em uma exposição índia. Revista Esferas, v.1 n. 16, 2020. p. 122-132. 
                <br></br>
                <Link 
                    src={"https://portalrevistas.ucb.br/index.php/esf/article/view/11087"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/08_Livro rasgado_Artigo_ManoeldeBarros_Artigo_Manoel de Barros.jpg",
            legenda: (<>
                DOBAL, Susana. Manoel de Barros: entre fotografias do idioma inconversável das pedras. Revista Studium (UNICAMP). Studium, Campinas, SP, n. 40, 2018. p. 188-210.
                <br></br>
                <Link 
                    src={"https://econtents.bc.unicamp.br/inpec/index.php/studium/article/view/12612"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/11_Tempo fotografico e tempo cinematografico_Imagem4_Gordon_Artigo_Tempo fotografico.jpg",
            legenda: (<>
                DOBAL, Susana. Tempo fotográfico e tempo cinematográfico: reciprocidades. Revista Laika, USP, v. 1 n.2, 2012. p.. 1-17.
                <br></br>
                <Link 
                    src={"https://www.revistas.usp.br/revistalaika/article/view/137176"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/Hamish Fulton_1000px.jpg",
            legenda: (<>
                DOBAL, Susana. Hamish Fulton: muitas caminhadas e uma obra. Revista Studium, UNICAMP, n. 36. Julho, 2014.  
                <Link 
                    src={"https://www.studium.iar.unicamp.br/36/3/#_ftn1"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/01_Dobal_DSC_2895.jpg",
            legenda: (<> 
                DOBAL, Susana. Paranoia: um delírio entre a poesia, a fotografia e o cinema. Revista Studium, UNICAMP, n. 32, inverno 2011.  
                <br></br>
                <Link 
                    src={"http://www.studium.iar.unicamp.br/32/5.html"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/01Dobal_Brancusi_McCall_Artigo.jpg",
            legenda: (<>
                DOBAL, Susana. Três imagens para relatar esculturas impalpáveis. Concinnitas (Rio de Janeiro, UERJ), v. 1 Ano 10, p. 42-53, 2009. 
                <br></br>
                <Link 
                    src={"https://www.e-publicacoes.uerj.br/index.php/concinnitas/article/view/55522"}
                />
            </>)
        },
        {
            src: "assets/texto/artigos/08_Magritte-golconda.jpg",
            legenda: (<>
                (texto literário) DOBAL, Susana. Carta Proibida a alguém no passado. Revista Letra Magna. Vol. 19 n. 32, 2023. São Paulo, Instituto Federal de Educação, Ciência e Tecnologia. 
                <br></br>
                <Link 
                    src={"https://ojs.ifsp.edu.br/index.php/magna/article/view/2214"}
                />
                <br></br>
                <a href="/#/site/texto/artigos/cartaproibida">Ver mais</a>
            </>)
        },
    ]
    
    return <div className="page-background">
        <Header />
        <TopBarImage2 />
        <Container className="artigos-container page-height">
            <h4 className="page-title">artigos - destaques</h4>
            <CarouselDefault images={images}/>
        </Container>
        <Container>
            <div className="line-orange"></div>
        </Container>
        <Container>
            {texts.map((text) => (
                <Row style={{textAlign: "left"}}>
                    <p>
                        — {text.main}&nbsp; 
                        {text.link && 
                            <a className="link-default" href={text.main} >
                                {text.link}
                            </a>
                        }
                    </p>
                </Row>
            ))}
            <Row style={{textAlign: "left", marginBottom:"40px"}}>
                <p><b>
                    Para mais artigos ver&nbsp;
                    <a className="link-default" href={"/#/site/bio/cv"} >
                        cv
                    </a>
                    &nbsp;ou para lista completa ver o&nbsp;
                    <a className="link-default" href={"http://lattes.cnpq.br/5063008053842213"} >
                        Currículo Lattes.
                    </a>
                </b></p>
            </Row>
        </Container>
        <Footer />
    </div>
}