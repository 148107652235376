import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { BsInstagram } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

import "../../css/screens/bio/Contato.css";

export default function Contato(){


    return <>
        <div>
        <Header />
            <div className="image-contato">
            <div className="orange-box">
            <Container style={{height:"100%"}}>
                <Row className="contato-text align-items-center">
                    <Col className="contato-col"  lg ={2} xs={6}>
                        
                            <img src="/assets/shared/contato2.png" />
                        
                    </Col>
                    <Col className="justify-content-center">
                        <Row className="susana-name">
                        Susana Dobal
                        </Row>
                        <Row className="susana-info">
                        Vive em Brasília.
                        </Row>
                        <Row className="susana-info">
                            <span className="contato-icon">
                            <AiFillMail /> 
                            </span>
                        sudobal@gmail.com
                        </Row>
                        <Row className="susana-info">
                            <span className="contato-icon">
                            <BsInstagram /> 
                        
                            </span>
                        @sudb
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
            </div>
        </div>     

    </>
}