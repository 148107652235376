import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";


export default function Jardim(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/01_Jardim Francês-Africa_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/02_Jardim Francês-SãoJoãoBatista_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/03_Jardim Frances-Beauvoir_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/04_Jardim Francês-Giacometti_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/05_Jardim Francês–Barbie_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/04_Jardim Frances_2005/06_Jardim Francês-Godard_1000px.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                        Jardim Francês
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Estátuas, esculturas, plantas ornamentais, ambientes elaborados compõem os jardins, locais em que natureza e cultura se encontram. Na França, os jardins são cultuados seja no espaço urbano, como nos jardins parisienses, seja pela presença deles na arte em geral, exposições, filmes, e textos literários dedicados a esse tema. Um jardim imaginário foi então fotografado durante uma estadia no sul da França. 

                        </p>
                        <p>
                        Se no Brasil estamos acostumados a ser o objeto do olhar estrangeiro, aqui um olhar também estrangeiro atravessou para o outro lado do espelho deixando de ser reflexo, para ser quem olha.
                        </p>
                        <p>
                        Pequenos nichos reúnem, então, nesse jardim quase fictício elementos da cultura francesa: a presença americana, e de artistas e escritores franceses ou não, o que foi trazido das antigas colônias, jornais, um cardápio japonês, uma miscelânea que fragmenta a idéia de nacionalidade e que procura resgatar elementos novos que estão no ar, e que subsitutem as estátuas brancas e clássicas ou a racionalidade do jardim francês, geométrico e apaziguado. 
                        </p>
                        <p>
                        A miscelânea proposta faz parte de uma natureza impregnada de cultura, mas ocorre entre muros, cercas, pedras, flores e folhas – uma diversidade de cores e texturas ainda fazem do jardim um abrigo a um só tempo conceitual e sensual. 
                        </p>
                        <p>
                            Susana Dobal
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        O Jardim Francês , 2005.
                        </div>
                        <div>
                        quinze cópias cromogênicas 40 x 60 cm. 
                        </div>
                        <div>
                        Ensaio apresentado na exposição coletiva do grupo Ladrões de Alma realizada no Anexo Cultural da Caixa Econômica do Rio de Janeiro em maio de 2005. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}