import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";


export default function Deslocamento(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/01_Fita_IMG_2484business_1000px.jpg",
            legenda: "No aeroporto: Bem-vindos investidores à grande Paris. Uma capital mundial dos negócios. "
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/02_FitaIMG_2480Hermes_1000px.jpg",
            legenda: "Na calçada: Vamos ver a vitrine da boutique Hermès?"
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/03_FitaIMG_2473amour_1000px.jpg",
            legenda: "No metrô, o anúncio oscilante de uma revista feminina: Sim ao amor. O prazer sem preocupações."
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/04_Fita_IMG_2498journaliste_1000px.jpg",
            legenda: "À mesa: Eu não sei mais seu sou jornalista porque não me reconheço no que vejo."
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/05_Fita_IMG_2538gare_1000px.jpg",
            legenda: "Na estação Gare de Lyon:  Desculpe, senhor, mas você é a quinta pessoa a me pedir dinheiro."
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/06_FitaIMG_2544chat_1000px.jpg",
            legenda: "À mesa: Eu gosto do Jean Jacques Rousseau. É o nome do meu gato, aliás."
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/07_Fita_IMG_2491comfraseLit_1000px.jpg",
            legenda: "Na revista escancarada sobre a cama: uma iguaria que já sugere prazer e volúpia, antes mesmo de ser mordida. "
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/08_Fita_IMG_2496pierre_1000px.jpg",
            legenda: "Acariciando a pedra do muro: Aqui antes havia o nome de um deus romano que foi apagado pelos cristãos. "
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/09_Fita_IMG_2492Mercedes_1000px.jpg",
            legenda: "Espancando a porta do elevador do estacionamento: Envie a carcaça de merda que a sua BMW ou o seu Mercedes eu levo lá pra cima! "
        },
        {
            src: "assets/fotografia/02_No mundo/02_Deslocamento_2009/10_FitaIMG_2489metro_1000px copy.jpg",
            legenda: "No metrô, em Paris: Não se preocupe, nós vamos reencontrá-lo."
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        nas bocas
                    </div>
                    <div className="namidia-text-subtitle">
                        Deslocamento
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        As fotografias apresentadas na Galeria Depardieu, em Nice, foram realizadas durante um ano na França e são o produto de experimentações com a linguagem fotográfica e a palavra. A partir de frases aparentemente aleatórias, constrói-se um elo entre viagens de trem e fragmentos de textos. A fita azul, de um azul ultramarino caro à cultura francesa, sugere uma continuidade entre vozes e lugares. Este relato sobre a França se apoia na possibilidade de alinhavar o sentido a partir de fragmentos. O que está em questão não é o espaço físico francês, mas uma cultura difusa que se materializa em vozes ouvidas nas ruas, bares, metrôs, nas palavras lidas em anúncios ou em jornais. As fotografias das frases foram intercaladas, na exposição em Nice, com um ensaio fotográfico de pessoas no trem em movimento.
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        O ensaio fotográfico Déplacement foi mostrado na exposição Deux artistes brésiliens à Nice  (Luciano Figueiredo e Susana Dobal) na Galeria Christian Depardieu, em Nice, 2009, onde também foi exposto um vídeo que combinava uma narrativa de texto e fotografia. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}