import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";


export default function Glossario(){
    let images = [
        {
            src: "assets/fotografia/glossario/01_novo_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/02_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/03_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/04_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/05_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/06_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/07_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/08_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/09_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/10_Glossario_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/glossario/11_Glossario_1000px_mn.jpg",
            legenda: ""
        },
    ]

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        na mídia
                    </div>
                    <div className="namidia-text-subtitle">
                        Pequeno Glossário para explicar um estranho fenômeno
                    </div>
                    <div className="namidia-maintext">
                        <p>
                            Dez termos em inglês e um adendo, distribuídos por um cenário universitário, acompanham o fio condutor que liga atentados partindo do Realengo, no Rio de Janeiro, a Christchurch, na Austrália.  Esses neologismos vão além da brutalidade dos ataques e desvendam comunidades subterrâneas de onde emergem esses termos recém-saídos da conversa entre grupos de habitantes do submundo virtual. Na superfície, pessoas perambulam com seus celulares por um mesmo campus universitário onde ecoa ainda, vindos de outros tempos, um crime passional e uma ameaça de atentado contra um grupo de estudantes. Haveria alguma ligação entre todos esses fatos?
                        </p>
                        <p>
                            Ensaio exposto no Prêmio Nacional de Fotografia Pierre Verger - menção honrosa na categoria Inovação e Experimentação. Palacete das Artes, Salvador, BA, 2019 (em português) e publicado na revista AfterImage, University of California (UCLA), March 2023.
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            11 imagens 30 x 45. Impressão a jato de tinta em papel de algodão. 
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper" >
                        <CarouselDefault 
                            images={images}
                            heigth="900px"
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}