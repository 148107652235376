import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";


export default function Branco(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/Dobal-EmBranco01-Esquema.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco01_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco02_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco03_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco04_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco05_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco06_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco07_1000px.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/05_Em branco_2001/EmBranco08_1000px.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                        Em Branco
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Em ensaio anterior, Vozes em NY, folhas de papel espalhadas por Nova Iorque traziam frases ouvidas em andanças pela cidade. Nesse caso, a ausência da palavra remete ao silêncio de Brasília, onde há muitos gramados, espaços vazios e poucos transeuntes, mas também a qualquer folha em branco, ao estado de vulnerabilidade presente no flanar pela cidade, atividade tão cara à fotografia.
                        </p>
                        <p>
                        Folhas em branco falam de locais de encontros e desencontros, sons e vozes esparsas. Essas folhas alinhavam fragmentos, registram um silencioso flanar, trazem o testemunho de sensações aparentemente desconexas e associações invisíveis. A fotografia é uma página em branco onde se tenta escrever um texto coerente. Para isso, os olhos estão abertos, a boca está fechada, o ouvido está atento. Até que, em estranha alquimia, a imagem adquira voz. 

                        </p>
                        <p>
                        Exposição coletiva que ocorreu simultaneamente em dois espaços e teve a curadoria de Elder Rocha Filho, Evandro Salles e Marília Panitz. No Anexo Cultural da Caixa Econômica, na Mostra Caixa Arte Contemporânea Brasília 2005, foi exposta a obra "Em Branco" (e no Centro Cultural Brasil Espanha, na exposição Mirações, foi exposta a obra "Cotidiano").
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            Em branco, 2001.
                        </div>
                        <div>

                    Exposição Coletiva Situações Brasília 2005. Anexo Cultural da Caixa Econômica, Brasília. 
                        </div>
                        <div>
                    Oito impressões em sais de prata e doze papeis em branco 

                        </div>
                        <div>
                    30 x 40 cm. 

                        </div>

                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}