import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../css/screens/MainPage.css"


import { Carousel } from 'react-responsive-carousel';



import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import {redirect, useNavigate} from "react-router-dom";
// import MainCarousel from "../components/MainCarousel";


export default function Index(){
    const navigate = useNavigate();

    let images = [
        {
            src: "assets/carousel/00.jpg",
            route:'/'
        },
        {
            src: "assets/carousel/01.jpg",
            route:'/site/texto/artigos'
        },
        {
            src: "assets/carousel/02.jpg",
            route:'/site/fotografia/namidia/mundo'
        },
        {
            src: "assets/carousel/03.jpg",
            route: '/site/fotografia/namidia/vento'
        },
        {
            src: "assets/carousel/04.jpg",
            route: '/site/fotografia/nomundo/mar'
        },
        {
            src: "assets/carousel/06.jpg",
            route: '/site/fotografia/nasbocas/deslocamento'
        },
        {
            src: "assets/carousel/07.jpg",
            route: '/site/fotografia/namidia/glossario'
        },
        {
            src: "assets/carousel/08.jpg",
            route:'/site/texto/artigos'
        },
        {
            src: "assets/carousel/09.jpg",
            route: '/site/texto/entrevistas'
        },
        {
            src: "assets/carousel/10.jpg",
            route: '/site/texto/entrevistas'
        },
        {
            src: "assets/carousel/11.jpg",
            route: '/site/texto/artigos'
        },
    ]

    return <div className="bodyContainer">
        <Header />
        <Container className="carouselContainer ">
            <Carousel 
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                centerMode={true}
                centerSlidePercentage={58}
                autoPlay={true}
            >
                {images.map((image, index)=>(   
                    <div className="carouselImageContainer cursorHand"
                         onClick={()=>{
                         navigate(image.route)
                        }}
                    >
                    <img
                      className="carouselImage"
                      src={image.src}
                     alt={''}/>
                    </div>       
                ))}
            </Carousel>

            <Footer />
        </Container>
    </div>
}