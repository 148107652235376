import { useEffect } from "react";
import Header from "../../components/Header";
import TopBarImage from "../../components/TopBarImage";
import "../../css/screens/fotografia/NasBocas.css"
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NasBocas(){
    const navigate = useNavigate();
    
    function addImageAnimation(nometexto:string,  nomeimage:string){
        let image:any = document.querySelector(nomeimage);
        let text = document.querySelector(nometexto);
        
        text?.addEventListener('mouseover', ()=>{
            image.style.opacity = 1;
        })

        text?.addEventListener('mouseout', ()=>{
            image.style.opacity = 0;
        })
    }

    function addRedirect(element:string, src:string){
        let dv:any = document.querySelector(element);

        dv?.addEventListener('click', ()=>{
            navigate(src);
        })
    }

    useEffect(()=>{
        addImageAnimation('.namidia-text-entrevistas', '.image-entrevistas')
        addImageAnimation('.namidia-text-deslocamento', '.image-deslocamento')
        addImageAnimation('.namidia-text-retratos', '.image-retratos')
        addImageAnimation('.namidia-text-love', '.image-love')
        addImageAnimation('.namidia-text-vozes', '.image-vozes')

        addRedirect('.namidia-text-entrevistas', '/site/texto/entrevistas')
        addRedirect('.namidia-text-deslocamento', '/site/fotografia/nasbocas/deslocamento')
        addRedirect('.namidia-text-retratos', '/site/fotografia/nasbocas/retratos')
        addRedirect('.namidia-text-love', '/site/fotografia/nasbocas/love')
        addRedirect('.namidia-text-vozes', '/site/fotografia/nasbocas/vozes')
    }, [])

    return <>
        <Header />
        <TopBarImage />
        <div className="namidia-mainContainer">
            <Container>
                <Row className="namidia-textoContainer">
                    <Col>
                        <Row className="namidia-text-namidia">
                            nas bocas
                        </Row>
                        <Row className="namidia-text-entrevistas">
                            Entrevistas
                        </Row>
                        <Row className="namidia-text-deslocamento">
                            deslocamento
                        </Row>
                        <Row className="namidia-text-retratos">
                            Retratos falantes
                        </Row>
                        <Row className="namidia-text-love">
                            Love Stories
                        </Row>
                        <Row className="namidia-text-vozes">
                            Vozes em NY
                        </Row>
                    </Col>
                    <Col>
                    <Row className="namidia-imageContainer">
                        <div className="image-entrevistas">
                            <img src="assets/texto/entrevistas/paginaabertura/Andre_Rouille_DSC_0544_2009.jpg" />
                        </div>
                        <div className="image-deslocamento">
                            <img src="/assets/fotografia/02_No mundo/02_Deslocamento_2009/06_FitaIMG_2544chat_1000px.jpg" />
                        </div>
                        <div className="image-retratos">
                            <img src="assets/fotografia/NasBocas/02_Retratos falantes_10000px/01RetratosFalantes_IMG_2840_VD.jpg" />
                        </div>
                        <div className="image-love">
                            <img src="assets/fotografia/NasBocas/03_Love Stories_2001/Love_Stories_1000px/06_Love_Stories_aquario_2001_1000px.jpg" />
                        </div>
                        <div className="image-vozes">
                            <img src="assets/fotografia/NasBocas/04_Vozes em NY_1998/Imagens_Vozes_em_NY/01_Vozes_em_NY_2022-2.jpg" />
                        </div>

                    </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    </>
}