import Footer from "../components/Footer";
import Header from "../components/Header";
import TopBarImage from "../components/TopBarImage";

import "../css/screens/Bio.css";
import {Container} from "react-bootstrap";

export default function Bio(){

    return <>
        <Header />
        <TopBarImage />
        <div className="page-background">

        <Container className="bio-container">
            <div className="bio-titulo">
                <b>
                mini bio
                </b>
            </div>
            <div className="bio-text">
                <p>
                    Susana Dobal participou de mais de trinta exposições (fotografia, instalação e vídeo) no Brasil, USA e França, publicou sobre fotografia, cinema, arte contemporânea, é professora associada da Universidade de Brasília (UnB). Tem doutorado em História da Arte (CUNY/GC), pós-doc na Université Paris 8 (2009) e na Aix-Marseille Université (AMU 2014). Graduou-se em Jornalismo (UnB), e em Língua Portuguesa e Literatura Brasileira (UnB), fez especialização em Teoria da Literatura (UnB) e mestrado em Fotografia (New York University/International Center of Photography). Tem experiência na área de Comunicação e Artes. Recebeu menção honrosa no Prêmio Nacional de Fotografia Pierre Verger (2019) na categoria Inovação e Experimentação. Tema principal de pesquisa: experimentações em narrativas visuais.
                </p>
                <p>
                    <i>
                        Susana Dobal participated on more than thirty exhibitions in Brasília, Rio de Janeiro, São Paulo, New York, Madrid, Buenos Aires and Nice, has published about photography, cinema and contemporary art and is associate professor at the University of Brasilia. She has a PhD in Art History from CUNY/GC and pos-doc from Université Paris 8 and Aix-Marseille Université. She is graduated in Photography at the International Center of Photography and New York University (NYU/ICP) and has previous degrees in Journalism (UnB) and Brazilian Literature (UnB - University of Brasilia). As a photographer, she received honorable mention from the Pierre Verger Photography National Award (Brazil – 2019) in the category Innovation and Experimentation. Main research theme: experimentations in visual narratives.
                    </i>
                </p>
                <p >
                    Currículo Lattes:  <a href={'http://lattes.cnpq.br/5063008053842213'}>http://lattes.cnpq.br/5063008053842213</a>
                </p>
            </div>
        </Container>
        <Footer />
            </div>
    </>
}