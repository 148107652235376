import React, { useState } from 'react';
import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/texto/Entrevistas.css";
import CarouselDefault from "../../../components/CarouselDefault";
import EntrevistaImageModal from "../../../components/EntrevistaImageModal";



export default function TiagoSantana(){
    const array15 = Array(15).fill(false);

    /*** Modal Control  */
    const [modalControl, setModalControl] = useState(Array(15).fill(false));

    const handleClose = (n:number) => () => {let m = modalControl.slice(); m[n]=false; setModalControl(m);}
    const handleOpen = (n:number) => () => {let m = modalControl.slice(); m[n]=true; setModalControl(m);}
    const ModalLink = (props:any) => (
        <a onClick={handleOpen(props.n)} className='modal-link'>
            <b>
                &nbsp;[fig. {props.n+1}]
            </b>
        </a>
    );

    /*** Image control */
    function getImagePath(n:number){
        return  "assets/texto/entrevistas/03_Tiago Santana/Imagens_Entrevista_Tiago_Santana_1000px/" + 
        n.toString().padStart(2, '0') + 
        "_Tiago_Santana_1000px.jpg"
    }

    function generateSrc(index:any){
        return {
            src: getImagePath(index),
            legenda: (<>
                <b>Figura {index}</b>
            </>)
        }
    }

    let images = () => new Array(15).fill(0).map((e, idc)=>{
        return generateSrc(idc + 1);
    })

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="entrevista-mainBody">
                <Row className="entrevista-titulo">
                    <b>
                        Tiago Santana - entre fotografias, fragmentos e livros
                    </b> 
                </Row>
                <Row>
                    <div className="entrevista-sub-text">
                    Entrevista originalmente publicada na Revista de Estudos Brasileños, Universidad de Salamanca e USP, v. 4, n.8, p. 177-189, jul-dez 2017.
                    </div>
                    <div className="entrevista-sub-text">
                        <a>
                        https://revistas.usal.es/cuatro/index.php/2386-4540/article/view/reb201748177189/19802
                        </a>
                    </div>
                    <div className="entrevista-sub-text">
                    Entrevista e fotos Susana Dobal 
                    </div>
                </Row>
                <Row className="entrevista-texto">
                    <p>
                    Tiago Santana é conhecido por suas imagens em preto e branco do Nordeste, que trazem um olhar de intimidade com o local e as pessoas. Para o seu primeiro projeto que resultou no livro Benditos, de 2001, ele recebeu uma Bolsa Vitae e trouxe um novo ar para a fotografia documental brasileira ao olhar de perto para o nordeste com suas composições dinâmicas e cortes radicais. O tema inicial era bastante conhecido, a romaria em Juazeiro do Norte, no Ceará, porém ele foi renovado com enquadramentos que associam fragmentos de corpos e de imagens religiosas reunindo tudo em uma atmosfera eloquente e misteriosa. Desde então, ele publicou outros livros com diferentes abordagens para a região, seja trabalhando com a literatura, no livro baseado na obra de Graciliano Ramos, No Chão de Graciliano (2007), ou com a música, no livro dedicado à Luiz Gonzaga, No Céu de Luiz (2014) – esses dois publicados em parceria com o jornalista Audálio Dantas. Além de ter recebido diversos prêmios, ele é ainda o segundo fotógrafo brasileiro a entrar na coleção francesa Photo Poche, idealizada pelo célebre editor Robert Delpire, o primeiro a reconhecer e publicar o clássico The Americans, do Robert Frank. O interesse de Tiago Santana pela fotografia vai além da atuação como fotógrafo, ele é também fundador da editora Tempo d’Imagem especializada em livros de fotografia.
                    </p>
                    <p>
                    Nessa entrevista, Tiago Santana fala do seu percurso, suas influências e seu engajamento com a fotografia que não pode ser separado da relação forte que ele tem com a sua região. Isso pode ser percebido pela maneira como seus enquadramentos associam diversos planos, fragmentos, personagens, plantas, animais e objetos que povoam o agreste nordestino. Com seus cortes radicais, suas fotografias nos obrigam a ver tudo como fazendo parte de um mesmo conjunto de elementos tornados mais expressivos pela maneira como apenas insinuados como fragmentos incompletos. A entrevista foi realizada em uma visita do fotógrafo à Brasília em que ele ofereceu uma oficina sobre livros de fotografia. Um ensaio fotográfico acompanha essa entrevista. Ele faz parte de uma série de entrevistas com fotógrafos e teóricos com o intuito de refletir sobre a fotografia por meio da palavra e da imagem, a partir da obra dos entrevistados ou de elementos sugeridos pelo pensamento deles, seja um pensar por imagens ou com palavras. O interesse de Tiago Santana pela imagem é indissociável do seu interesse pelos livros de fotografia e pela vontade de usar imagens e livros como uma forma de estabelecer contato com as pessoas. Naquela ocasião, os livros serviram de ponte para falar principalmente de edições que ele trouxe, mas também dos projetos futuros de todos ao redor. Isso poderá ser visto, ao se clicar nos links das figuras que levam ao ensaio paralelo à entrevista. 
                    </p>
                    <div className="green-box">
                        Como surgiu o interesse pela fotografia na sua vida?
                    </div>
                    <p>
                    Ela vem de várias situações 
                    <ModalLink n={0}/>
                    . Na realidade vem desde o local onde eu nasci. Começa com o fato de meus pais, que eram cearenses mas moraram na Bahia, terem voltado para o Ceará. Foram obrigados a isso porque era a época da ditadura e meu pai foi demitido da Petrobrás. O exílio dele foi no interior do Estado, enquanto outros foram para fora do país. Ele teve que se esconder a uns 600 km da capital, na região do Cariri que acho a síntese do Nordeste, onde fica Juazeiro, Cratos, Barbalha...   O fato de eu ter nascido em uma região como essa, em torno de toda aura e mistério que existe por causa da romaria do Padre Cícero e para onde vão pessoas do nordeste inteiro, um celeiro de cultura popular, uma criança que passou boa parte da sua infância e adolescência em um lugar assim, fica marcado visualmente. Então isso foi determinante para eu me tornar fotógrafo. O meu pai tinha também uma relação com o audiovisual. Ele sempre teve laboratório improvisado em casa, fez álbuns de família transitou pelo super 8, pelo 16 mm, sempre tinha os projetores, todo um aparato de audiovisual que ele usava no trabalho, na militância e mesmo em casa. Eu terminei também usando esses dispositivos, fiz super 8, que foi uma grande escola pra mim antes da fotografia. Quando saí de Juazeiro, fui para a universidade em Fortaleza, estudar engenharia, ocorreu a Semana Nacional de Fotografia na universidade que foi outro terceiro fator decisivo. Ali eu vi que a fotografia não era apenas uma coisa isolada, havia todo um movimento em torno dela. Quando eu descobri que havia fotógrafos no Brasil todo discutindo, pensando, refletindo, eu comecei a entender que a fotografia tinha uma importância muito maior na minha vida 
                    <ModalLink n={1}/>
                    . 

                    </p>
                    <div className="green-box">
                    O fato de fotografar fez com que você olhasse para essa região de uma maneira diferente?

                    </div>
                    <p>
                    Quando eu resolvi ser fotógrafo eu queria fazer um trabalho sobre aquele lugar. De certa forma, uma das razões também foi a necessidade de contar um pouco da minha história, da minha experiência daquele lugar, tentar interpretar aquilo através de imagens. Não era uma reportagem, até porque Juazeiro, sendo um lugar de romarias populares, é um dos locais mais fotografados do Nordeste. Eu queria fazer uma coisa mais íntima 
                    <ModalLink n={2}/>
                    , a partir da vivência de alguém que conhece bem aquele universo, falar da experiência e da atmosfera daquele lugar.

                    </p>
                    <div className="green-box">
                    A sua maneira de fotografar é bem peculiar, usando muitos cortes e associando fragmentos. Você já começou fotografando assim ou foi descobrindo isso ao longo do seu percurso?

                    </div>
                    <p>
                    Eu sempre falei que tinha que fazer um trabalho em Juazeiro. Acabou durando oito anos desde as primeiras imagens até o final, de 1992 a 2000. Ali se deu todo o meu aprendizado e amadurecimento como fotógrafo. Meu olhar foi se aprimorando com o exercício de narrar essa história e com milhares de referências não só da fotografia mas também de outras áreas. Não fiz universidade de fotografia, na época nem existia, só algumas cadeiras de fotografia. Meu aprendizado se deu ali no exercício e no compartilhar com outros fotógrafos o que eu fazia. Em um determinado momento, quando resolvi publicar o Benditos, o olhar estava amadurecido. Descobri então coisas importantes que eu fazia de maneira inconsciente, como a relação das minhas imagens com os ex-votos, os fragmentos de corpos.

                    </p>
                    <div className="green-box">
                    Ou também da sua experiência de engenheiro, juntando partes...

                    </div>
                    <p>
                    (Riso). Ao ter que falar e pensar sobre o trabalho, a gente acaba aprendendo muito sobre ele. Eu nunca tinha pensado como a engenharia, a experiência do meu pai como engenheiro, e minha experiência dentro da engenharia que era engenharia mecânica, que é uma coisa de juntar partes e fazer mecanismos, tinha uma influência no meu trabalho. Duas coisas da minha infância são interessantes em Juazeiro. Uma é que eu enterrei uma câmera do meu pai 
                    <ModalLink n={3}/>
                    , eu era muito menino, e muito tempo depois ela foi descoberto enterrada, estava acabada. Eu até gostaria de ter essa câmera hoje. 

                    </p>
                    <div className="green-box">
                    E você enterrou por quê?

                    </div>
                    <p>
                    Pode ter sido porque peguei escondido, não  me lembro, deveria ter seis ou sete anos. Vendo hoje, pode ter sido uma semente que acabou brotando 
                    <ModalLink n={4}/>
                    . A segunda coisa foi a experiência que tive com os meus pais de me permitirem ousar, experimentar, arriscar e procurar ser feliz. Digo isso porque abandonei a engenharia, o que não é fácil. Tem também a história da bicicleta. Tinha um seriado, Shazan e Xerife, eles andavam pelo Brasil com uma bicicleta voadora. Cismei que a minha bicicleta ia voar. Convenci o meu pai a entrar na oficina e fazer uma bicicleta com hélices, que obviamente não ia voar. Houve o dia em que eu fiz a tentativa de voar e não voou. Ele poderia ter dito desde o início que não voaria, mas esse exercício foi muito importante pra mim como tentativa e risco. Acho que assimilei isso depois na fotografia.

                    </p>
                    <div className="green-box">
                    Você comentou na oficina que te agrada os trabalhos da Graciela Iturbide 
                    <ModalLink n={5}/>
                    , da Cristina García Rodero e do Josef Koudelka. O que especialmente te agrada no trabalho deles?

                    </div>
                    <p>
                    Eles são marcantes por várias razões. Primeiro por voltarem o trabalho para o lugar deles, mais especialmente a Graciela e a Cristina, a fotógrafa mais importante do México e a mais importante da Espanha 
                    <ModalLink n={6}/>
                    . É um pouco do que eu faço. Os ritos, os costumes, tem a questão da religiosidade que é também muito forte nesses países, a forma de tratar, o cuidado, a imersão, todos três têm muito isso, uma fotografia que vai além do registro e faz pensar, tem mistério, tem algo intrigante. Eles são referência importante porque a fotografia que eu acredito é essa, a que falta um pedaço, é estranha em determinados momentos mas que tem cuidado com a estética que é não só a estética em si, mas a estética que reflete a imersão e a relação do fotógrafo com o seu lugar. A força do trabalho vem justamente dessa imersão profunda. 


                    </p>
                    <div className="green-box">
                    Qual objetiva você prefere usar?

                    </div>
                    <p>
                    Uma das razões que eu me tornei fotógrafo foi também porque eu era muito tímido quando era adolescente, e a fotografia veio da necessidade de me comunicar mais. Já que com as palavras era mais complexo pra mim eu quis me comunicar com a imagem. Eu sempre utilizei uma grande angular na minha vida, a primeira objetiva que eu comprei foi uma 28mm. Na verdade ela nem é uma grande angular aberrante, mas ela te força a estar perto das pessoas; é uma tentativa também de me forçar a estar junto com o outro 
                    <ModalLink n={7}/>
                    .
                    </p>
                    <div className="green-box">
                    Ela está também relacionada ao fato de o seu trabalho associar os personagens ao ambiente e juntar partes diferentes?

                    </div>
                    <p>
                    Claro, além disso a grande angular permitia, na construção da imagem, ter diversas pequenas imagens dentro da mesma imagem. Você amplia um pouco o espaço visual com diversas cenas, como se eu quisesse alargar o meu olhar nessa tentativa de me aproximar das pessoas. Mesmo assim, estando perto de alguém e vendo algo que está acontecendo em outro plano. A grande angular permite mais facilmente isso.

                    </p>
                    <div className="green-box">
                    Mas ultimamente você tem usado mais uma Leica com uma objetiva fixa que é uma panorâmica. Como ela foi assimilada no seu trabalho?

                    </div>
                    <p>
                    Ao longo da vida, no processo de construção do trabalho, a gente vai refletindo sobre o que faz e vendo muitas coisas, dá vontade de trabalhar com outras formas de ver, como um quadrado da 6 x 6 
                    <ModalLink n={8}/>
                    . Não é o caso agora da panorâmica que é 6 x17, não é uma Leica, é uma Linhof e que usa um negativo. Eu usei uma Leica bastante, que eu tenho ainda, usei no Benditos, No chão de Graciliano, que é uma câmera pequena, discreta, isso pra mim era importante. Principalmente naqueles trabalhos que exigiam uma certa delicadeza no encontro com o outro. Eu sempre fui um fotógrafo que utilizou pouco equipamento, eu tento interferir quanto menos melhor. Eu sou um fotógrafo silencioso. Eu tive um desejo te alargar esse espaço, que é o espaço expandido do sertão. Também por influência do Koudelka, que usa uma objetiva bem parecida, ele usa panorâmica mas para fotografar paisagens construídas ou desconstruídas pelo homem no livro que é como que o ícone dele que é o Caos 
                    <ModalLink n={9}/>
                    . Eu comecei a experimentar essa câmera para No chão de Graciliano. Ela tem várias características importantes, a primeira é a questão do tempo. É como se eu estivesse indo contra corrente desse nosso tempo onde tudo é imediato, você aperta o botão e faz 100, 200.000 fotos. Nessa câmera eu ponho um filme e faço quatro fotos. Tem um tempo muito diferente de operar. O filme também tem uma imagem que vai ficar latente, então ainda tem o tempo de revelação, tem a surpresa do não visto, do não revelado imediatamente que também me interessa como processo. Em terceiro lugar tem ainda a própria materialidade do negativo. Eu confesso que todos os meus trabalhos feitos até hoje são com o negativo. O digital pra mim é como se estivesse faltando algo. Nesse sentido, especialmente o 6 x 17, ele por si só já é um resultado, é quase que a obra. Eu penso até em trabalhos futuros usar o negativo como parte da exposição ou da obra em si.

                    </p>
                    <div className="green-box">
                    Benditos é um trabalho sobre a fé, sobre a relação das pessoas com a religiosidade que aparece na peregrinação, no apego a símbolos religiosos, nos gestos. No chão de Graciliano já é um livro sobre a relação das pessoas com o meio em volta e ali já aparecem algumas fotos realizadas com a panorâmica. Foi a panorâmica que originou esse olhar que une pessoas e cenário em uma unidade de fragmentos ou foi a obra de Graciliano Ramos que te sugeriu essa assimilação do ambiente?

                    </div>
                    <p>
                    Foram as duas coisas. Usar a câmera permitiu ampliar o espaço, incluir mais a paisagem e ao mesmo tempo ampliar também a cena, fragmentá-la até mais. E além disso tem a obra do Graciliano, que é pautada no homem mas a paisagem também é importante. Quando resolvi fazer essa documentação, fiz a partir de um entendimento de que aquele universo dele existe ainda hoje. Parece que estão ali os mesmos personagens, a mesma cachorra Baleia, todos os elementos. Hoje o sertão tem mudado muito também, existem referências da modernidade, por exemplo, as parabólicas, a moto que substitui o cavalo. Mas naquele momento o meu olhar deu um recorte muito específico, é como seu estivesse vendo ali, abstraindo esses elementos da modernidade que não estavam presentes, eu me concentrei em elementos que ainda eram iguais, os personagens eram os mesmos, o sentimento era o mesmo, a paisagem...

                    </p>
                    <div className="green-box">
                    Por outro lado tem algo nas fotos que parece ser mais uma contribuição sua, um afeto entre as pessoas, ou das crianças com os animais, tem sempre um gesto, um afago...

                    </div>
                    <p>
                    Tem duas coisas nesse processo de documentação de um lugar, na verdade no sertão é muito presente, uma é a criança e a outra é o animal. E a relação do homem e da criança com os animais; eles são praticamente membros da família. Tem uma foto No chão de Graciliano em que tem um burrinho que está praticamente  dentro da casa, com a cabeça entrando e um meninozinho na janela pegando nele.
  
                    </p>
                    <div className="green-box">
                    Tem outra das vacas entrando, tem galinhas também...

                    </div>
                    <p>
                    Naquela das vacas, a comida ficava dentro da sala então ela entrava pra comer. Tem uma foto também que é muito emblemática da relação do homem e da criança com o animal: é aquela em que o menino está deitado, ele quase se confunde com o jumentinho, pra mim aquilo simboliza não só essa relação mas a delicadeza dessa relação porque é muito íntimo, aquilo faz parte de toda vida cotidiana. Então é inevitável ter as crianças e os animais nesse trabalho. E um dos livros que foi um livro de cabeceira nesse projeto foi um livro do Graciliano chamado Infância 
                    <ModalLink n={10}/>
                    em que justamente ele fala da infância dele em Buíque, no sertão de Pernambuco, contado a partir do ponto de vista da criança.
 
                    </p>
                    <div className="green-box">
                    Às vezes, nos seus enquadramentos, as pessoas se confundem com as imagens religiosas em volta, a composição leva a uma equivalência entre as pessoas e essas imagens. Tem também reflexos no espelho, sombras e rostos que se confundem com as imagens dos santos. Seriam as pessoas reais, que aparecem em tantos duplos, a religião possível que suas imagens procuram criar?

                    </div>
                    <p>
                    Eu acho interessante essa leitura. Tem uma coisa especificamente em Juazeiro, é que o Padre Cícero, na realidade nem é um santo oficial da Igreja Católica, agora tem um movimento, mas quando ele morreu estava impossibilitado de rezar missa por não ser reconhecido como Santo que fez milagres, pelo contrário, a Igreja Católica foi muito dura com ele no final. Não estou aqui questionando se isso é certo ou errado, mas o que faz com que as pessoas se confundam com o universo do santo é que o padre Cícero não é um santo que vem de Portugal, da Itália. Aqui temos poucos santos, e o Padre Cícero é um santo muito próximo, recente, contemporâneo, o padrinho, ou padinho, um segundo pai, uma coisa muito quente e próxima do nordestino. É como se fosse algum parente, alguém muito próximo, então de certa forma as pessoas se confundem. No Juazeiro, nessa região, a maioria das crianças nascidas em uma determinada época se chamou Cícero, tem muitos Cíceros, então essa mistura de pessoas e santos é como se uma coisa estivesse muito ligada a outra muito intimamente.

                    </p>
                    <div className="green-box">
                    Você, que inicialmente se dedicou ao tema religioso, tem fé?

                    </div>
                    <p>
                    Eu tenho fé em muitas coisas (risos). Obviamente sou de uma família católica, fui batizado e tal. Não sou um praticante mas quando eu vejo milhares de pessoas dentro de uma igreja com o Padre Cícero, aquela energia toda voltada para um desejo... Eu me lembro bem que tem uma hora que se diz [ele cantarola] “A nós descei divina luz...” – parece que o negócio vai descer ali! Acho importante a fé em algo, que pode ser também em outras religiões. Uma energia voltada para um desejo tem que ser respeitada, independente do santo ou da religião, eu acredito nessa força. E mais ainda se pensamos no caso da Igreja Católica, em uma determinada visão que não é exatamente a visão do Padre Cícero, de que o Cristo é considerado como alguém transgressor ou transformador. Então acho que nas religiões podem ser encontradas coisas interessantes e transformadoras.
  
                    </p>
                    <div className="green-box">
                    Apesar dessa religiosidade difusa que aparece mesmo quando o tema não é religião, tem também nas suas fotos um forte apelo à materialidade, com texturas diferentes, um primeiro plano muito próximo e corpos fragmentados que intensificam a presença das coisas. A sua fotografia sugere também uma experiência física do mundo bastante presente. Você acha que isso está relacionado com a sua insistência em trabalhar com o analógico?

                    </div>
                    <p>
                    Tem duas questões aí que são importantes: primeiro a minha necessidade de ir ao encontro do outro, estar próximo, muitas vezes até estar próximo demais, sentir o cheiro. E acho que isso se reflete nas imagens, nos planos, nas texturas,  sensações, às vezes a pele, eu acho que isso é muito presente. Já o analógico tem essa coisa que você pode tocar, pegar.

                    </p>
                    <div className="green-box">
                    Você não fotografa nunca com o digital?

                    </div>
                    <p>
                    Eu tenho digital, fotografo, mas não para os meus trabalhos pessoais. Eu uso como um cadernos de anotações, faço imagens para pensar, refletir, fazer depois no analógico porque tenho esse desejo da materialidade 
                    <ModalLink n={11}/>
                    . Não tenho nada contra o digital, mas no meu processo de construção é fundamental o tempo, a materialidade, um certo respeito ao tempo do lugar. Talvez se eu for fazer um trabalho em uma grande metrópole isso não faça sentido. Mas um trabalho feito no sertão, é como se o analógico, ainda mais levado ao extremo ao usar uma câmera panorâmica que só faz quatro fotos com um filme, eu acho que é um pouco entender e respeitar o tempo daquele lugar.

                    </p>
                    <div className="green-box">
                    Você tem uma experiência de recriar o local que você fotografou no espaço da exposição. Como você tem feito para produzir essa experiência diferenciada da fotografia nas suas exposições?

                    </div>
                    <p>
                    Eu tive o privilégio de já no meu primeiro trabalho passar por todas as etapas desde a ideia até o resultado final, que é um livro ou a exposição. Eu acho que a gente fotografa para passar essa nossa experiência de vida ou de encontro e de alguma forma poder compartilhar com o outro. Eu acho que ao expor, que é uma forma de compartilhar, você tem que ir buscar elementos condizentes com o trabalho e que possam potencializar essa experiência. É como se a fotografia não desse conta dessa experiência por si só, na maioria das vezes a experiência é muito maior que o resultado, então eu tenho feito isso. Eu na verdade fiz poucas exposições com esse processo, até porque precisa de reflexões, as coisas não são feitas assim tão rápido. Eu acho isso importante no livro como experiência narrativa, mas também na exposição onde envolve iluminação, textura, cor, uma série de coisas, sensações, som.
 
                    </p>
                    <div className="green-box">
                    Já faz alguns anos que você está trabalhando com a sua própria editora de livros de fotografia, a Tempo d’Imagens. Como você sente esse mercado de livro de fotografia no Brasil?

                    </div>
                    <p>
                    Ainda é um mercado difícil. Existe uma demanda reprimida de SDes, projetos de fotógrafos importantes que ainda não tiveram um livro até hoje. Imagina como era quando a gente começou há quase 20 anos atrás. Benditos tem 16 anos. Eu tive o privilégio de ter o meu primeiro trabalho publicado em livro. Era uma época em que pouquíssimos fotógrafos tinham um livro. A necessidade de criar a editora foi para suprir um pouco isso. Primeiro porque a minha formação toda foi pelos livros, na minha experiência familiar a minha casa sempre teve uma biblioteca, meu avô era um intelectual que tinha uma biblioteca fantástica em casa. Tem até aquela história do labirinto de estantes e no meio ficava o bureau dele. O livro foi então muito importante. E tem ainda uma militância, a vontade de viabilizar projetos não só meus, mas de outros fotógrafos também. E é o que temos feito, embora nem tanto como gostaríamos. Temos muitos projetos na gaveta que ficamos tentando tirar, então existe uma infinidade de SDes importantes que achamos que deveriam ter seu trabalho publicado porque é o livro que vai ficar para gerações futuras, para as bibliotecas, então eu acredito na força e na potência do livro
                    <ModalLink n={12}/>
                    .

                    </p>
                    <div className="green-box">
                    Você distribui seu livro para as pessoas de onde você fotografou?

                    </div>
                    <p>
                    Olha, eu tenho um grande prazer de fazer isso quando eu posso. Obviamente tem situações em que isso não é possível por várias razões. Nas romarias você cruza com pessoas que provavelmente nunca mais vai ver. Mas quando é possível fazer isso, eu faço questão que as pessoas olhem e ficamos comentando aquela visão sobre eles.

                    </p>
                    <div className="green-box">
                    Com Benditos você fez uma exposição lá, não foi?

                    </div>
                    <p>
                    Eu fiz uma exposição não só em Fortaleza, como fiz questão de fazer no lugar. Foi muito intenso, virou quase um lugar de romaria. As pessoas se reconheciam nas fotos. Às vezes se reconheciam em fotos tremidas que eu nem tenho certeza se eram elas, mas para elas eram, então acabei dando o livro. É quase como se fosse a minha promessa, já que Juazeiro é um lugar de promessas, de pedidos, de agradecimentos  e tal, onde os ex-votos estão presentes, o meu ex-voto era um livro. Se eu pudesse levar para uma sala de milagres, eu levaria um livro e colocaria lá. Aliás não coloquei, deveria colocar, vou fazer isso.

                    </p>
                    <div className="green-box">
                    O seu ensaio publicado em livro mais recente foi sobre o Luís Gonzaga. Como você resolveu o desafio de unir música e fotografia?

                    </div>
                    <p>
                    Eu acabei sem querer, nunca é sem querer, eu fiz um tipo de trilogia. Eu fiz um livro em que eu parti da experiência visual, que foi o Benditos, com toda a questão do impacto visual que é a cidade de Juazeiro do Norte com a romaria; fiz um livro a partir da palavra, que são as palavras que Graciliano usou sobre aquele mesmo lugar, que eu fico interpretando com imagens e no Luiz Gonzaga eu fiz a partir do som que também é muito peculiar, muito presente, os sons que estão naquele lugar, e um dos ícones desse som é o Luiz Gonzaga que também contava a história daquele lugar assim como as fotos. Foi quase que uma coisa natural. Não é que eu planejei os projetos uma a um, cada um deles foi acontecendo de forma bem diferente. Claro que um leva ao outro. O Graciliano aconteceu por causa de um convite a partir de Benditos. E no fundo no fundo está tratando do mesmo lugar. É como se todos os três fossem em projeto só.

                    </p>
                    <div className="green-box">
                    Então no caso do Luiz Gonzaga você então pensou mais nas letras do que no som...

                    </div>
                    <p>
                    Nas letras e no som também. A palavra como som, palavra cantada, porque tem a força da letra mas tem a força da música. 

                    </p>
                    <div className="green-box">
                    Mas como a música transparece na fotografia?

                    </div>
                    <p>
                    Ela é inspiradora, por isso utilizo nas minhas projeções a música. A música cria atmosfera. A do Luiz Gonzaga cria a atmosfera do sertão, como outras músicas do Nordeste, não é só a dele. Tem a banda de pífanos e as bandas cabaçais que não têm palavras, são só sons. Eu usei o Luiz Gonzaga por uma relação também dele com o meu lugar. O Luiz Gonzaga é praticamente cearense. Exu, a cidade de nascimento dele onde eu pautei o trabalho fica na fronteira com o Ceará, a poucos quilômetros do Crato que é onde eu nasci. Quando o Luiz Gonzaga era criança, a mãe dele ia fazer feira em Crato, que era a cidade de referência, hoje já mudou muito. O Cariri, fica no limite. Tanto é que a população do sul do Ceará fala com um sotaque mais pernambucano. O Luiz Gonzaga tinha uma relação com o Juazeiro, com o Padre Cícero, com o Patativa, ele fazia parte disso apesar de ser pernambucano.

                    </p>
                    <div className="green-box">
                    Tem até uma entrevista do Eugene Smith em que ele comenta muito sobre a música e em um momento ele diz sobre a fotografia dele: “eu aprendi tudo com Bethoven”.

                    </div>
                    <p>
                    O Cartier Bresson também faz uma relação da partitura com o negativo. A música sempre esteve presente por exemplo no laboratório de fotografia onde eu sempre usei para me inspirar; também nas noites visuais em que a gente projetava, alguém lembrou aqui dos cromoclique – eram cliques da imagem a partir do cromo, em que a gente usava sons.

                    </p>
                    <div className="green-box">
                    Você tem algum projeto em andamento?

                    </div>
                    <p>
                    Eu tenho muitos projetos em andamento. Estou fazendo cinqüenta anos e faço um trabalho para rever tudo que produzi ao longo das últimas três décadas. Sempre trabalhei com projetos muito específicos, com começo, meio e fim. Ao longo disso, eu fui fazendo vários outros ensaios fotográficos ou imagens soltas que foram ficando nas gavetas. Agora faço um projeto para rever todo esses guardados, tudo que é incompleto. A partir de uma obra incompleta, de um SD incompleto, de uma vida incompleta que espero que eu ainda tenha muito tempo para produzir, de uma fotografia incompleta que é uma fotografia em que falta alguma coisa e faz com que quem olha queira completar, queira entender e transformar, enfim, todas essas incompletudes que podem ser fotos em Brasília, no sertão, em Nova Iorque, em Moscou, no México, não importa, nos vários lugares onde eu já estive. O sentido que vai criar essa narrativa terá que vir dessa relação do homem, que é o fundamental no meu trabalho, e fragmentos que possam encontrar uma maneira de linkar isso.

                    </p>
                    <div className="green-box">
                    Parece que você está descrevendo o seu próprio enquadramento porque você junta partes inusitadas em um enquadramento só.

                    </div>
                    <p>
                    Eu acho que é isso. Eu chamo de incompletudes, mas é um processo de experiência, eu tenho o desejo e o processo como eu vou fazer, mas o resultado é muito imprevisível 
                    <ModalLink n={13}/>
                    .

                    </p>
                    <div className="green-box">
                    Você também tem um projeto de um centro cultural, não é mesmo?

                    </div>
                    <p>
                    Esse projeto tem a ver com esse outro. Para fazer esse projeto eu precisava de um espaço em que ele pudesse ser desenvolvido. Eu aluguei então e estou transformando esse espaço que era um antigo hotel. É um lugar com que eu tenho uma relação de afeto na cidade, é um lugar muito interessante e que, engraçado, se chamava Hotel Lux. O nome Lux tem a ver com imagem, com luz. A ideia é transformar o espaço para abrigar o arquivo e todo o processo que vou produzir a partir desse arquivo e que eu pudesse compartilhar com outros, com amigos, fotógrafos, pessoas de várias áreas, acadêmica, técnica, pessoas que colaboraram muito ao longo do meu percurso, pessoas para quem eu pudesse abrir meu processo de trabalho para que me ajudem e ao mesmo tempo eu aprenda junto com elas. Então eu queria criar uma espécie de bolsa para jovens, vão ter quartos para residência,  o próprio espaço vai se transformando arquitetonicamente e ao mesmo tempo a obra vai sendo criada.  O próprio espaço no final se transforma na exposição, todas as etapas seriam produzidas dentro do espaço. É como se eu tivesse um centro cultural ou a galeria um ano, um ano e meio antes do resultado, à disposição para experimentar, para criar. Então é um projeto de alguma forma complexo, ousado, mas acho que pode ser muito rico e depois esse lugar pode vir a ser um local para outros projetos de SDes que também possam compartilhar a experiência com o outro 
                    <ModalLink n={14}/>
                    . Acho que é quase uma militância, como seu eu tivesse devolvendo um pouco tudo o que eu aprendi, compartilhando com o outro para que as novas gerações possam também aproveitar disso.

                    </p>
                </Row>
                <Row className="entrevistas-carousel-row">
                    <CarouselDefault images={images()} heigth={"700px"}/>
                </Row>
                <Row>
                    {
                        array15.map((v, i)=> (
                            <EntrevistaImageModal 
                                src={getImagePath(i+1)}
                                isOpen={modalControl[i]}
                                handleClose={handleClose(i)}
                                key={"modal-entrevista-"+i}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}