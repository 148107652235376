import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import "../css/components/Footer.css"

export default function Footer(){
    return <>
        <div className="footerBody">
            <p>
            © Susana Dobal, 2023.
            <a href="https://pation.dev/" className="footerlink">
                Desenvolvido por Pation Software.
            </a>
            </p>
        </div>
    </>
}