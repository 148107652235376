import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import CarouselDefault from "../../../components/CarouselDefault";


export default function Caminho(){
    let images = [
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/02_DSC7597_turner_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/03_DSC8775-titanic_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/04_DSC8538-toalhafucsia_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/05_DSC8744_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/08_DSC8593-paraiso_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/10_DSC8227_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/02_No mundo/01_A Caminho_2013/11_DSC8573_800px_mn.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        no mundo
                    </div>
                    <div className="namidia-text-subtitle">
                        A caminho
                    </div>
                    <div className="namidia-maintext">
                        <p>
                        Paisagens vistas na estrada entre Brasília e São Raimundo Nonato, no Piauí, pareciam sugestivas: além da natureza, um Brasil desfilava pela pista. Muitas e esparsas motocicletas levando gente, pacotes, botijões; caminhões escoando a produção, alguns poucos carros, pessoas a pé no meio do nada, gado disperso e a vegetação se transformando como se fosse uma veloz galeria de diferentes paisagistas cada qual com a sua visão particular de cenários ora mais selvagens, ora inteiramente cultivados por extensas áreas da monocultura, principalmente da soja. 
                        </p>
                        <p>
                        Na hora do apuro, Riobaldo, do Grande Sertão: Veredas do Guimarães Rosa, voltava-se para algum detalhe, o barulho dos tiros no couro estendido do boi, as roupas penduradas no arame farpado também no meio do tiroteio. Poderia parecer que isso era uma fuga, porém mais provavelmente era apenas a revelação de que, no momento em que a vida grita, os detalhes podem também adquirir uma súbita intensidade. 
                        </p>
                        <p>
                        São mostradas aqui algumas das imagens dessa série exposta no Foto Rio, em 2013. 
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                        Exposição de dez fotografias com texto. 80 x 63cm. 2012-2013. Impressão pigmentada sobre papel Hahnemühle. Foto Rio 2013. Rio de Janeiro: Centro Cultural da Justiça Federal. O mesmo ensaio foi exposto na Galerie Christian Depardieu (2014), em Nice, junto com o ensaio Repères, realizado na França.
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}