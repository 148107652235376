import { Container, Row } from "react-bootstrap";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TopBarImage2 from "../../../components/TopBarImage2";

import "../../../css/screens/fotografia/NaMidiaSubPage.css"
import Slider from "react-slick";
import CarouselDefault from "../../../components/CarouselDefault";
import { CarouselLink } from "../../../components/MainCarousel";


export default function Vento(){
    let images = [
        {
            src: "assets/fotografia/vento/01_Dobal_Figos_ECD12D7E-D125_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/02_Dobal_Queimada_02_C04DC915-4405_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/03_Dobal_Feministas_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/04_Dobal_Livro_FA6FCE55-9CAF_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/05_Dobal_Indios_D910D712-6302_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/06_Dobal_Cogumelos_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/07_Dobal_Imprensa_62AAFDBA-2521_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/08_Dobal_Protestos_0D7F8483-B710_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/09_Dobal_Cobertor_72320907-B891_1000px_mn.jpg",
            legenda: ""
        },
        {
            src: "assets/fotografia/vento/10_Dobal_Mother_4B94485D-DF07_1000px_mn.jpg",
            legenda: ""
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        <Header />
        <TopBarImage2 />
        <div className="page-body">
            <Container className="container-mainBody">
                <Row>
                    <div className="namidia-text-maintitle">
                        na mídia
                    </div>
                    <div className="namidia-text-subtitle">
                        O que o vento ainda não levou
                    </div>
                    <div className="namidia-maintext">
                        <p>
                            As portas estão fechadas e o mundo arde ao redor, arde tanto que as labaredas entram pelas telas do computador e do celular e terminam inflamando também o lar, os objetos, os livros, a papelada, as plantas, as tigelas, os alimentos, tudo amalgamado com as notícias, marinando em uma fusão que une intimidade e ultraje. Vozes e imagens externas povoam os rituais cotidianos: ardem as florestas, flexibiliza-se o porte de armas, nega-se a ciência. Os fatos aparecem como peças dispersas que uma vez reunidas ganham uma incômoda coerência.  Menosprezando a pandemia, a necropolítica reinante inventa paliativos inúteis, opõe-se ao uso de máscaras onde elas eram obrigatórias, veta ajuda para prevenção à Covid-19 a indígenas e a presidiários. Enquanto isso, filmes sobre a época do nazismo trazem um burburinho de eterno retorno. A pandemia vai afetando o cenário doméstico do confinamento: se antes eram as vidas privadas que se expunham na rede, agora é a vida pública que se imiscui na vida particular. Reuniões profissionais fisgam a intimidade do lar; o movimento contrário, porém, é ainda mais intenso. O mundo virou do avesso e não foi só porque o absurdo vigora nos acontecimentos mais diversos do momento político no Brasil: quando o lado de fora veio para dentro, uma onda de espanto trouxe um sabor infame para dentro das panelas. 
                        </p>
                    </div>
                    <div className="namidia-subtext">
                        <div>
                            O que o vento ainda não levou
                        </div>
                        <div>
                            Ensaio publicado nos Cadernos do Ceam, n. 37
                        </div>
                        <div>
                            Arte e Inovação em Tempos de Pandemia 2 
                        </div>
                        <div>
                            “Artes Visuais”. Rodolpho Ward (ed.). Brasília, DF: UnB/Centro de Estudos Multidisciplinares, 2022. P. 110-120.
                        </div>
                        <div>
                            <CarouselLink 
                                src={"https://www.academia.edu/72103054/Cadernos_do_Ceam_37_Arte_e_Inova%C3%A7%C3%A3o_em_Tempos_de_Pandemia_2_Artes_Visuais_"}
                            />
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="namidia-carousel-wrapper">
                        <CarouselDefault 
                            images={images}
                        />
                    </div>
                </Row>
            </Container>
        </div>
        <Footer />
    </>
}